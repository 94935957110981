import { useEffect } from 'react';
import { useAddress } from '@noah-labs/fe-shared-feature-address';
import { KycAccessControlData, KycReminderOrigin } from '@noah-labs/fe-shared-feature-kyc';
import { useSardineFlow } from '@noah-labs/fe-shared-feature-sardine';
import type { SmWithdraw } from '@noah-labs/fe-shared-feature-wallet';
import {
  WithdrawAccountController,
  WithdrawCompleteController,
  WithdrawConfirmController,
  WithdrawEnterAmountController,
  WithdrawNetworkController,
} from '@noah-labs/fe-shared-feature-wallet';
import { generatePath, Switch404, useStateMachine } from '@noah-labs/fe-shared-ui-components';
import type { PpUseWallet } from '@noah-labs/fe-shared-ui-shared';
import { TpAuthStatus, useRouter } from '@noah-labs/fe-shared-ui-shared';
import { walletRoutes } from '@noah-labs/fe-shared-util-routes';
import { Feature, SardineFlows } from '@noah-labs/shared-schema-gql';
import { matchPath, Route } from 'react-router-dom';
import { AccessControlData } from '../../../auth/AccessControlData';
import { WalletControllerWrapper } from '../../components/layout/WalletControllerWrapper';

const emptyState: SmWithdraw = {
  cryptoAmount: '',
  fetchedAt: '',
  fiatAmount: '',
  payeeData: undefined,
  price: '',
  withdrawOrderId: '',
};

export function WithdrawRouter(): React.ReactElement {
  useSardineFlow({ flow: SardineFlows.CryptoWithdraw });
  const { data, setData } = useAddress();
  /**
   * Remove destination address from the address context on unmount
   */
  useEffect(() => () => setData(undefined), [setData]);

  let initialState;
  if (data) {
    initialState = {
      ...emptyState,
      cryptoAmount: data.amount || '',
      payeeData: data,
    };
  }

  const sm = useStateMachine<SmWithdraw>({
    emptyState,
    initialState,
    name: 'Withdraw',
  });
  const { pathname } = useRouter();
  const { params } =
    matchPath<PpUseWallet>(pathname, {
      path: walletRoutes().send.withParams,
    }) || {};

  // if user hasn't scanned or enter an address, we have to go back to the scanner
  const addressInvalidRedirect = !sm.state.payeeData?.address;

  // if any data is missing, we can't show confirm or complete screens
  const confirmInvalid = !sm.state.cryptoAmount || !sm.state.payeeData?.address;
  const completeInvalid = !sm.state.cryptoAmount || !sm.state.payeeData?.address;

  return (
    <Switch404>
      <Route exact path={walletRoutes().send.base}>
        <AccessControlData needsAuthStatus={[TpAuthStatus.authenticated]}>
          <KycAccessControlData bypassIfUserHasBalance origin={KycReminderOrigin.Withdraw}>
            <WalletControllerWrapper
              backButton={false}
              backTo={generatePath(walletRoutes().base, params)}
              headTitle="Select account"
            >
              <WithdrawAccountController />
            </WalletControllerWrapper>
          </KycAccessControlData>
        </AccessControlData>
      </Route>
      <Route exact path={walletRoutes().send.network}>
        <AccessControlData
          needsAuthStatus={[TpAuthStatus.authenticated]}
          needsFeature={[Feature.Withdraw]}
          redirectInvalid={
            addressInvalidRedirect &&
            params &&
            generatePath(walletRoutes().send.address.base, params)
          }
        >
          <KycAccessControlData bypassIfUserHasBalance origin={KycReminderOrigin.Withdraw}>
            <WalletControllerWrapper headTitle="Select network">
              <WithdrawNetworkController />
            </WalletControllerWrapper>
          </KycAccessControlData>
        </AccessControlData>
      </Route>
      <Route exact path={walletRoutes().send.enterAmount}>
        <AccessControlData
          needsAuthStatus={[TpAuthStatus.authenticated]}
          needsFeature={[Feature.Withdraw]}
          redirectInvalid={
            addressInvalidRedirect &&
            params &&
            generatePath(walletRoutes().send.address.base, params)
          }
        >
          <KycAccessControlData bypassIfUserHasBalance origin={KycReminderOrigin.Withdraw}>
            <WalletControllerWrapper headerTitle="Enter Send Amount" headTitle="Enter Send Amount">
              <WithdrawEnterAmountController {...sm} />
            </WalletControllerWrapper>
          </KycAccessControlData>
        </AccessControlData>
      </Route>
      <Route exact path={walletRoutes().send.confirm}>
        <AccessControlData
          needsAuthStatus={[TpAuthStatus.authenticated]}
          needsFeature={[Feature.Withdraw]}
          redirectInvalid={
            confirmInvalid && params && generatePath(walletRoutes().send.enterAmount, params)
          }
        >
          <KycAccessControlData bypassIfUserHasBalance origin={KycReminderOrigin.Withdraw}>
            <WalletControllerWrapper
              headerTitle="Confirm Transaction"
              headTitle="Confirm Transaction"
            >
              <WithdrawConfirmController {...sm} />
            </WalletControllerWrapper>
          </KycAccessControlData>
        </AccessControlData>
      </Route>
      <Route exact path={walletRoutes().send.complete}>
        <AccessControlData
          needsAuthStatus={[TpAuthStatus.authenticated]}
          needsFeature={[Feature.Withdraw]}
          redirectInvalid={
            completeInvalid && params && generatePath(walletRoutes().send.enterAmount, params)
          }
        >
          <WalletControllerWrapper backButton={false} headTitle="Processing Withdrawal">
            <WithdrawCompleteController {...sm} />
          </WalletControllerWrapper>
        </AccessControlData>
      </Route>
    </Switch404>
  );
}
