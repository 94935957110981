import { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { css } from '@emotion/react';
import { Divider, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import type { TpSelectOption } from '@noah-labs/fe-shared-ui-components';
import { InputField, MuiSvgIcon, Switch } from '@noah-labs/fe-shared-ui-components';
import { getCardSchemeIcon, usePrevious } from '@noah-labs/fe-shared-ui-shared';
import type { TpCkoCardDetailsSchema } from '@noah-labs/fe-shared-util-validation-schemas';
import { logger } from '@noah-labs/shared-logger/browser';
import type {
  FrameCardTokenizationFailedEvent,
  FrameCardTokenizedEvent,
  FrameElement,
  FrameElementIdentifer,
  FramesInitProps,
  FrameValidationChangedEvent,
} from 'frames-react';
import { CardNumber, Cvv, ExpiryDate, Frames } from 'frames-react';
import type { UseFormReturn } from 'react-hook-form';
import { FormProvider } from 'react-hook-form';
import useScript from 'react-script-hook';
import { webConfigBrowser } from '../../../../webConfigBrowser';
import { CheckoutField } from '../payment/CheckoutField';
import { BillingAddressesForm } from './BillingAddresses/BillingAddressesForm';
import type { TpBillingAddressForm } from './BillingAddresses/schema';

const framesUrl = 'https://cdn.checkout.com/js/framesv2.min.js';

type TpCkoFields = Pick<TpCkoCardDetailsSchema, 'cardNumber' | 'cvv' | 'expiryDate'>;

export type TpTokenizedCardForm = TpCkoCardDetailsSchema & {
  billingAddress: TpBillingAddressForm;
  saveCard: boolean;
};

export type PpTokenizedCardForm = {
  countries: TpSelectOption[] | undefined;
  defaultAddress: TpBillingAddressForm | undefined | null;
  formId: string;
  isVisible: boolean;
  methods: UseFormReturn<TpTokenizedCardForm>;
  onCardTokenized?: (cardTokenized: FrameCardTokenizedEvent, save: boolean) => Promise<void>;
  saveToggle: boolean;
};

const checkoutFieldNames: Record<FrameElementIdentifer, keyof TpCkoFields> = {
  'card-number': 'cardNumber',
  cvv: 'cvv',
  'expiry-date': 'expiryDate',
};

const ckoFocusInitial = {
  cardNumber: false,
  cvv: false,
  expiryDate: false,
};

// frames doesn't support custom fonts as yet
const fieldFontFamily = 'Helvetica,sans-serif;';

export function TokenizedCardForm({
  countries,
  defaultAddress,
  formId,
  isVisible,
  methods,
  onCardTokenized,
  saveToggle,
}: PpTokenizedCardForm): React.ReactElement | null {
  const [framesLoading, framesError] = useScript({ checkForExisting: true, src: framesUrl });
  const [tokenizedFormKey, resetForm] = useReducer((key: number) => key + 1, 0);
  const wasVisible = usePrevious(isVisible);
  const [cardScheme, setCardScheme] = useState<string | undefined>(undefined);

  const theme = useTheme();
  const styles = {
    ckoInputBase: {
      color: theme.palette.text.primary,
      fontFamily: fieldFontFamily,
      fontSize: theme.typography.paragraphBodyM?.fontSize,
      fontWeight: theme.typography.paragraphBodyM?.fontWeight,
      letterSpacing: 'normal',
      lineHeight: '1.2',
      padding: theme.spacing(0, 2),
    },
    ckoInputPlaceholder: {
      base: {
        color: theme.palette.text.light,
      },
    },
    input: {
      fontFamily: fieldFontFamily,
    },
    saveCard: css`
      margin-top: ${theme.spacing(3)};
      justify-content: space-between;
    `,
  };

  const { formState, setFocus, setValue, watch } = methods;
  const billingAddress = watch('billingAddress');
  const cardholderName = watch('cardholderName');

  const CardSchemeIcon = getCardSchemeIcon(cardScheme);

  const framesConfig: FramesInitProps = useMemo(() => {
    const config: FramesInitProps = {
      acceptedPaymentMethods: webConfigBrowser.cko.supportedFiatPaymentCards,
      cardholder: {
        billingAddress: {
          addressLine1: billingAddress.Street,
          addressLine2: billingAddress.Street2 ?? undefined,
          city: billingAddress.City,
          country: billingAddress.CountryCode,
          state: billingAddress.State,
          zip: billingAddress.PostCode,
        },
        name: cardholderName,
      },
      publicKey: webConfigBrowser.cko.publicKey,
      style: {
        base: styles.ckoInputBase,
        placeholder: styles.ckoInputPlaceholder,
      },
    };

    return config;
  }, [
    billingAddress.Street,
    billingAddress.Street2,
    billingAddress.City,
    billingAddress.CountryCode,
    billingAddress.State,
    billingAddress.PostCode,
    cardholderName,
    styles.ckoInputBase,
    styles.ckoInputPlaceholder,
  ]);
  /**
   * In order to make the styling of the inputs consistent,
   * we need to manage the field focus state and send to the CheckoutField
   */
  const [ckoFocus, setCkoFocus] = useState(ckoFocusInitial);
  const handleFrameFocus = useCallback(
    ({ element }: FrameElement) => {
      const target = checkoutFieldNames[element];
      setFocus(target);
      setCkoFocus({ ...ckoFocusInitial, [target]: true });
    },
    [setFocus],
  );
  const handleFrameBlur = useCallback(() => {
    setCkoFocus(ckoFocusInitial);
  }, []);

  const handleFrameValidation = useCallback(
    (validationEvent: FrameValidationChangedEvent): void => {
      const { element, isValid } = validationEvent;
      const fieldName = checkoutFieldNames[element];

      setValue(fieldName, String(isValid), {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true,
      });
    },
    [setValue],
  );

  const handleCardTokenizedFailed = useCallback(
    (errors: FrameCardTokenizationFailedEvent): void => {
      logger.debug('card tokenization failed', errors);
    },
    [],
  );

  const handleSubmit = useCallback(async () => {
    try {
      Frames.enableSubmitForm();
      await Frames.submitCard();
    } catch (error) {
      logger.error(error);
    }
  }, []);

  /**
   * Add event handler for card tokenization
   * Frames handlers do not update when the value of the callback changes
   */
  const saveCard = watch('saveCard');
  useEffect(() => {
    if (!onCardTokenized || framesLoading) {
      return;
    }
    Frames.removeAllEventHandlers('cardTokenized');

    Frames.addEventHandler(
      'cardTokenized',
      // @ts-expect-error type definition is wrong
      (cardTokenized: FrameCardTokenizedEvent) => onCardTokenized(cardTokenized, saveCard),
    );
  }, [framesLoading, saveCard, onCardTokenized]);

  /**
   * Resets the form whenever the user navigates away from the page
   */
  useEffect(() => {
    if (isVisible || !wasVisible) {
      return;
    }

    resetForm();
  }, [isVisible, wasVisible]);

  if (framesLoading || framesError) {
    return null;
  }

  return (
    <Frames
      key={tokenizedFormKey}
      cardTokenizationFailed={handleCardTokenizedFailed}
      config={framesConfig}
      frameBlur={handleFrameBlur}
      frameFocus={handleFrameFocus}
      frameValidationChanged={handleFrameValidation}
      paymentMethodChanged={(event): void => {
        const { isPaymentMethodAccepted, paymentMethod } = event;
        if (!isPaymentMethodAccepted) {
          setCardScheme(undefined);
          return;
        }
        setCardScheme(paymentMethod);
      }}
    >
      <FormProvider {...methods}>
        <form id={formId} onSubmit={methods.handleSubmit(handleSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <InputField
                fullWidth
                required
                dataQa="name"
                inputProps={{ sx: styles.input }}
                label="Cardholder Name"
                name="cardholderName"
              />
            </Grid>
            <Grid item xs={12}>
              <CheckoutField
                // TODO (cs): use 40px for better visuals until we get updated icons
                EndSlot={<MuiSvgIcon svg={CardSchemeIcon} sx={{ width: '40px' }} />}
                error={formState.errors.cardNumber}
                FieldSlot={CardNumber}
                focused={ckoFocus.cardNumber}
                label="Card Number"
              />
            </Grid>
            <Grid item xs={6}>
              <CheckoutField
                error={formState.errors.expiryDate}
                FieldSlot={ExpiryDate}
                focused={ckoFocus.expiryDate}
                label="Expiry Date"
              />
            </Grid>
            <Grid item xs={6}>
              <CheckoutField
                error={formState.errors.cvv}
                FieldSlot={Cvv}
                focused={ckoFocus.cvv}
                label="CVV"
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="paragraphBodyMBold">Billing address</Typography>
            </Grid>
            <Grid item xs={12}>
              <BillingAddressesForm countries={countries} defaultAddress={defaultAddress} />
            </Grid>
          </Grid>
          {saveToggle && (
            <Switch
              dataQa="save-card"
              inputCss={styles.saveCard}
              label={
                <Typography color="text.light" variant="paragraphBodyM">
                  Save this card for future payments
                </Typography>
              }
              labelPlacement="start"
              name="saveCard"
            />
          )}
        </form>
      </FormProvider>
    </Frames>
  );
}
