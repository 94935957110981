import { useRef, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  AppContainer,
  AppFooter,
  AppHeaderTitle,
  AppMain,
  ButtonGroup,
  InlineLinkOrButton,
  PinInput,
  PrimaryButton,
  SceneHeader,
  SceneMain,
  SceneParagraph,
  SceneTitleLarge,
} from '@noah-labs/fe-shared-ui-components';
import type { TpDialogToggle } from '@noah-labs/fe-shared-ui-shared';
import type { TpPinForm } from '@noah-labs/fe-shared-ui-signing';
import { createPinSchema } from '@noah-labs/fe-shared-ui-signing';
import { pinLength } from '@noah-labs/shared-cryptography';
import { Helmet } from 'react-helmet';
import type { Resolver, SubmitHandler } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import { AppHeaderData } from '../../../components/layout/AppHeaderData';
import { CreatePinInfoDialog } from '../components/dialogs/CreatePinInfoDialog';
import { SkipPinDialog } from '../components/dialogs/SkipPinDialog';

export type PpCreatePinScene = {
  backTo?: string;
  canSkip?: boolean;
  exitTo?: string;
  onSubmit: SubmitHandler<TpPinForm>;
  pageTitle?: string;
  sceneConfirmTitle?: string;
  sceneTitle?: string;
};

const formId = 'create-pin';

export function CreatePinScene({
  backTo,
  canSkip,
  exitTo,
  onSubmit,
  pageTitle,
  sceneConfirmTitle = 'Confirm your security PIN',
  sceneTitle = 'Create your security PIN',
}: PpCreatePinScene): React.ReactElement {
  const [unconfirmedPin, setUnconfirmedPin] = useState<string | null>(null);
  const methods = useForm<TpPinForm>({
    defaultValues: {
      pin: '',
    },
    mode: 'onChange',
    resolver: zodResolver(createPinSchema(unconfirmedPin)) as Resolver<TpPinForm>,
  });

  const { handleSubmit, reset } = methods;
  const learnDialog = useRef<TpDialogToggle>(null);
  const skipDialog = useRef<TpDialogToggle>(null);

  return (
    <AppContainer>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <AppMain>
        <AppHeaderData helpButton backTo={backTo}>
          <AppHeaderTitle>{pageTitle}</AppHeaderTitle>
        </AppHeaderData>
        <SceneHeader>
          <SceneTitleLarge>{unconfirmedPin ? sceneConfirmTitle : sceneTitle}</SceneTitleLarge>
          <SceneParagraph>
            To ensure your account is safe and strongly protected, please enter 6 digits to set your
            security PIN. This will be used when sending funds.
          </SceneParagraph>
          <InlineLinkOrButton
            variant="paragraphBodyM"
            onClick={(): void => learnDialog.current?.toggle()}
          >
            Why is a 6 digit PIN safer than SMS 2FA?
          </InlineLinkOrButton>
        </SceneHeader>
        <SceneMain>
          <FormProvider {...methods}>
            <form
              id={formId}
              onSubmit={async (e): Promise<void> => {
                await handleSubmit(async (value) => {
                  // A fresh pin number is entered
                  // Reset the form and wait for confirmed pin
                  if (!unconfirmedPin) {
                    setUnconfirmedPin(value.pin);
                    reset();
                    return;
                  }
                  // Form validation already checks that the confirmed pin matches the entered pin
                  // but we do it again just in case
                  if (value.pin !== unconfirmedPin) {
                    return;
                  }

                  await onSubmit(value);
                })(e);
              }}
            >
              <PinInput name="pin" pinLength={pinLength} />
            </form>
          </FormProvider>
          <CreatePinInfoDialog ref={learnDialog} />
          <SkipPinDialog ref={skipDialog} exitTo={exitTo} />
        </SceneMain>
      </AppMain>
      <AppFooter>
        <ButtonGroup>
          <PrimaryButton
            color="primaryBrand"
            data-qa={unconfirmedPin ? 'pin-confirm-btn' : 'pin-submit-btn'}
            disabled={!methods.formState.isValid}
            form={formId}
            loading={methods.formState.isSubmitting}
            type="submit"
          >
            Continue
          </PrimaryButton>
          {canSkip && (
            <PrimaryButton
              color="primary"
              variant="text"
              onClick={(): void => skipDialog.current?.toggle()}
            >
              Skip and do it later
            </PrimaryButton>
          )}
        </ButtonGroup>
      </AppFooter>
    </AppContainer>
  );
}
