import { AccountController } from '@noah-labs/fe-shared-feature-wallet';
import { Switch404 } from '@noah-labs/fe-shared-ui-components';
import { walletRoutes } from '@noah-labs/fe-shared-util-routes';
import { Route } from 'react-router-dom';
import { AccountControllerWrapper } from './AccountWrapper';

export function AccountRouter(): React.ReactElement {
  return (
    <Switch404>
      <Route path={walletRoutes().account.base}>
        <AccountControllerWrapper>
          <AccountController />
        </AccountControllerWrapper>
      </Route>
    </Switch404>
  );
}
