import { useCallback } from 'react';
import { AddressManualScene, useAddressError } from '@noah-labs/fe-shared-ui-address';
import { generatePath } from '@noah-labs/fe-shared-ui-components';
import { useRouter, useWalletParams } from '@noah-labs/fe-shared-ui-shared';
import { walletRoutes } from '@noah-labs/fe-shared-util-routes';
import { useAddress } from '../hooks/useAddress';
import { useAddressData } from '../hooks/useAddressData';
import { useRecentAddresses } from '../hooks/useRecentAddresses';
import { getNextUrl } from '../utils/getNextUrl';

export function AddressManualController(): React.ReactElement {
  const { cryptoCurrency, params } = useWalletParams();
  const { push } = useRouter();

  const recentPayees = useRecentAddresses();

  const { scannedAddress, scannerUnavailable, setData } = useAddress();
  const { addressData, error, handleAddress, isLoading } = useAddressData();

  const handleNextUrl = useCallback(() => {
    if (!addressData) {
      return;
    }
    const nextUrl = getNextUrl(addressData);
    setData(addressData);
    push(nextUrl);
  }, [push, setData, addressData]);

  const handleRedirectToScan = useCallback(() => {
    push(generatePath(walletRoutes().send.address.scan, params));
  }, [push, params]);

  const { ApiErrorScene } = useAddressError(error);

  if (ApiErrorScene) {
    return ApiErrorScene;
  }

  return (
    <AddressManualScene
      addressData={addressData}
      currencyCode={cryptoCurrency.code}
      handleAddress={handleAddress}
      handleNextUrl={handleNextUrl}
      handleRedirectToScan={handleRedirectToScan}
      isLoading={isLoading}
      recentAddresses={recentPayees}
      scannedAddress={scannedAddress}
      scannerUnavailable={scannerUnavailable}
    />
  );
}
