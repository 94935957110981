import { useSignOut } from '@noah-labs/fe-shared-data-access-auth';
import { LoadingPage } from '@noah-labs/fe-shared-ui-components';
import { authRoutes } from '@noah-labs/fe-shared-util-routes';
import { useAuth } from '../hooks/useAuth';

export function SignOut(): React.ReactElement {
  const { onSignOut } = useAuth();

  useSignOut(onSignOut, authRoutes.signIn);

  return <LoadingPage message="Signing off, so long Arkonaut...!" />;
}
