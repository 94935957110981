import React from 'react';
import { useTheme } from '@mui/material/styles';
import {
  AppContainer,
  AvatarIconFromSvg,
  TextWithIcon,
  useDesktop,
} from '@noah-labs/fe-shared-ui-components';
import type { PpWC } from '@noah-labs/fe-shared-ui-shared';
import { TpAuthStatus, useWalletParams } from '@noah-labs/fe-shared-ui-shared';
import { walletRoutes } from '@noah-labs/fe-shared-util-routes';
import { useRouteMatch } from 'react-router-dom';
import { AppHeaderData } from '../../../../components/layout/AppHeaderData';
import { SidebarNavData } from '../../../../components/navigation/SidebarNavData';
import { useBackHijack } from '../../../../hooks/useBackHijack';
import { AccessControlData } from '../../../auth/AccessControlData';
import { Transaction } from './Transaction';

export function AccountControllerWrapper({ children }: PpWC): React.ReactElement {
  const { cryptoCurrency } = useWalletParams();
  const match = useRouteMatch<{ id: string }>(walletRoutes().account.transactions);
  const { backTo, goBack } = useBackHijack(walletRoutes().base);
  const isDesktop = useDesktop();
  const theme = useTheme();

  return (
    <AccessControlData needsAuthStatus={[TpAuthStatus.authenticated]}>
      <AppContainer headTitle={cryptoCurrency.label} SideNavbarSlot={<SidebarNavData />}>
        <AppHeaderData
          disableFade
          helpButton
          scanButton
          backButton={goBack}
          backgroundColor={theme.palette.grayscale.offWhite}
          backTo={backTo}
          fullWidth={isDesktop}
        >
          <TextWithIcon>
            <AvatarIconFromSvg size={2.5} svg={cryptoCurrency.svg} />
            <span>{cryptoCurrency.label}</span>
          </TextWithIcon>
        </AppHeaderData>
        {children}
      </AppContainer>
      <Transaction id={match?.params.id} />
    </AccessControlData>
  );
}
