import { useCallback, useMemo } from 'react';
import {
  useCountriesForFeature,
  useCountryFromCode,
} from '@noah-labs/fe-shared-data-access-countries';
import {
  useFiatPaymentMethodsQuery,
  useFiatPaymentMethodUpdateMutation,
} from '@noah-labs/fe-shared-data-access-wallet';
import { useUserInitUi } from '@noah-labs/fe-shared-feature-user';
import { PaymentCardUpdateErrorAlert, usePushAlert } from '@noah-labs/fe-shared-ui-components';
import type { TpPaymentCard } from '@noah-labs/fe-shared-ui-shared';
import { useRouter } from '@noah-labs/fe-shared-ui-shared';
import { useUserError } from '@noah-labs/fe-shared-ui-user';
import { userRoutes } from '@noah-labs/fe-shared-util-routes';
import { logger } from '@noah-labs/shared-logger/browser';
import type { CountryCode } from '@noah-labs/shared-schema-gql';
import { Feature } from '@noah-labs/shared-schema-gql';
import { compareStrings } from '@noah-labs/shared-util-vanilla';
import { useQueryClient } from 'react-query';
import { useRouteMatch } from 'react-router-dom';
import { webConfigBrowser } from '../../../webConfigBrowser';
import { useOptimisticDeletePaymentCard } from '../../wallet/components/payment/PaymentMethodsPicker/useOptimisticDeletePaymentCard';
import type { TpEditCardForm } from '../scenes/EditPaymentMethod';
import { EditPaymentMethodScene } from '../scenes/EditPaymentMethod';

const fpmQueryKey = useFiatPaymentMethodsQuery.getKey();

export function EditPaymentMethod(): React.ReactElement {
  const { data: userData, error } = useUserInitUi();
  const { data: countriesData } = useCountriesForFeature(
    userData?.userProfile.HomeAddress?.CountryCode,
    [[Feature.Buy, Feature.Sell]],
    webConfigBrowser.regions.ProhibitedCountries,
    webConfigBrowser.regions.RestrictedRegions,
  );

  const match = useRouteMatch<{ id: string }>(userRoutes.settings.editPaymentMethod);
  const pushAlert = usePushAlert();
  const { push } = useRouter();
  const queryClient = useQueryClient();

  const { data: card } = useFiatPaymentMethodsQuery(undefined, {
    select: (data): TpPaymentCard | undefined => {
      const decodedId = decodeURIComponent(match?.params.id ?? '');
      const maybeCard = data.fiatPaymentMethods.Items?.find((c) =>
        compareStrings(c.DynamoID, decodedId),
      );
      if (!maybeCard) {
        return undefined;
      }

      switch (maybeCard.Details.__typename) {
        case 'FiatPaymentCard':
          return {
            billingAddress: maybeCard.Details.BillingAddress,
            id: maybeCard.DynamoID,
            issuer: maybeCard.Details.Issuer,
            last4: maybeCard.Details.Last4,
            scheme: maybeCard.Details.Scheme,
          };
        default:
          return undefined;
      }
    },
  });

  const country = useCountryFromCode(userData?.userProfile.HomeAddress?.CountryCode);
  const defaultAddress = useMemo(() => {
    if (!country || !card?.billingAddress) {
      return undefined;
    }

    return {
      ...card.billingAddress,
      CountryName: country.name,
    };
  }, [card?.billingAddress, country]);

  const { mutateAsync: updateFiatPaymentMethod } = useFiatPaymentMethodUpdateMutation();
  const { mutateAsync: removeCard } = useOptimisticDeletePaymentCard();

  const onRemoveCard = useCallback(async () => {
    if (!card) {
      return;
    }

    try {
      await removeCard({
        Input: {
          DynamoID: card.id,
        },
      });

      pushAlert({
        key: 'removeSuccessful',
        message: 'Payment method removed',
        severity: 'success',
      });

      push(userRoutes.settings.paymentMethods);
    } catch (err) {
      // handled in useOptimisticDeletePaymentCard
    }
  }, [card, push, pushAlert, removeCard]);

  const onSubmit = useCallback(
    async (data: TpEditCardForm) => {
      if (!card?.id) {
        return;
      }

      try {
        await updateFiatPaymentMethod({
          Input: {
            BillingAddress: {
              City: data.billingAddress.City,
              CountryCode: data.billingAddress.CountryCode as CountryCode,
              PostCode: data.billingAddress.PostCode,
              State: data.billingAddress.State,
              Street: data.billingAddress.Street,
              Street2: data.billingAddress.Street2,
            },
            DynamoID: card.id,
          },
        });

        await queryClient.invalidateQueries(fpmQueryKey);

        pushAlert({
          key: 'fiatPaymentUpdateSuccessful',
          message: 'Payment method updated',
          severity: 'success',
        });

        push(userRoutes.settings.paymentMethods);
      } catch (err) {
        pushAlert(PaymentCardUpdateErrorAlert);
        logger.error(err);
      }
    },
    [card?.id, push, pushAlert, queryClient, updateFiatPaymentMethod],
  );

  const { ApiErrorScene } = useUserError(error);

  if (ApiErrorScene) {
    return ApiErrorScene;
  }

  return (
    <EditPaymentMethodScene
      cardDetails={card}
      countries={countriesData?.countries}
      defaultAddress={defaultAddress}
      fullName={userData?.userProfile.fullDisplayName}
      pageTitle="Edit Payment Method"
      onRemoveCard={onRemoveCard}
      onSubmit={onSubmit}
    />
  );
}
