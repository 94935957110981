export const userRoutes = {
  acceptUpdates: '/user/accept-updates',
  accounts: '/user/accounts',
  base: '/user',
  consent: '/user/consent',
  pin: {
    create: {
      pin: '/user/pin/create/pin',
      secretPhrase: '/user/pin/create/secret-phrase',
      success: '/user/pin/create/success',
    },
    reset: {
      pin: '/user/pin/reset/pin',
      secretPhrase: '/user/pin/reset/secret-phrase',
      success: '/user/pin/reset/success',
    },
    revocation: {
      revoke: '/user/pin/revoke',
      unrevoke: '/user/pin/unrevoke',
    },
    update: {
      currentPin: '/user/pin/update/current-pin',
      pin: '/user/pin/update/pin',
      success: '/user/pin/update/success',
    },
  },
  profile: '/user/profile',
  settings: {
    accountLimits: '/user/limits',
    base: '/user/settings',
    bitcoinUnit: '/user/settings/bitcoin-unit',
    consent: '/user/settings/consent',
    displayCurrency: '/user/settings/display-currency',
    editPaymentMethod: '/user/settings/payment-methods/:id',
    newPaymentMethod: '/user/settings/new-payment-method',
    paymentCurrency: '/user/settings/payment-currency',
    paymentMethods: '/user/settings/payment-methods',
    primaryCurrency: '/user/settings/primary-currency',
    securityPin: '/user/settings/security-pin',
    securityPinThreshold: '/user/settings/security-pin-threshold',
  },
  username: '/user/username',
};

/**
 * Export the paths that need to route to this router
 */
export const postSignupRoutes = [userRoutes.username, userRoutes.consent, userRoutes.acceptUpdates];
