import { zodResolver } from '@hookform/resolvers/zod';
import { Stack } from '@mui/material';
import {
  AppContainer,
  AppFooter,
  AppHeaderTitle,
  AppMain,
  GetInTouchLink,
  MnemonicInput,
  PrimaryButton,
  SceneHeader,
  SceneMain,
  SceneParagraph,
  SceneTitleLarge,
} from '@noah-labs/fe-shared-ui-components';
import type { TpPhraseForm } from '@noah-labs/fe-shared-ui-signing';
import { phraseSchema } from '@noah-labs/fe-shared-ui-signing';
import { Helmet } from 'react-helmet';
import type { Resolver, UseFormSetError } from 'react-hook-form';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { AppHeaderData } from '../../../components/layout/AppHeaderData';

export type PpEnterSecretPhraseScene = {
  onSubmit: ({ phrase }: TpPhraseForm, setError: UseFormSetError<TpPhraseForm>) => Promise<void>;
};

const formId = 'use-forgot-pin-enter-phrase';

export function EnterSecretPhraseScene({ onSubmit }: PpEnterSecretPhraseScene): React.ReactElement {
  const methods = useForm<TpPhraseForm>({
    defaultValues: {
      phrase: '',
    },
    mode: 'onSubmit',
    resolver: zodResolver(phraseSchema) as Resolver<TpPhraseForm>,
  });

  return (
    <AppContainer>
      <Helmet>
        <title>Forgot PIN</title>
      </Helmet>
      <AppMain>
        <AppHeaderData backButton helpButton>
          <AppHeaderTitle>Forgot PIN</AppHeaderTitle>
        </AppHeaderData>
        <SceneHeader>
          <SceneTitleLarge>Enter your secret recovery phrase</SceneTitleLarge>
          <SceneParagraph>
            This is the 24 word phrase you were given when signing up to NOAH.
          </SceneParagraph>
        </SceneHeader>
        <SceneMain dataQa={formId}>
          <Stack spacing={4}>
            <FormProvider {...methods}>
              <form
                id={formId}
                onSubmit={(e): void => {
                  void methods.handleSubmit(async (values) => {
                    await onSubmit(values, methods.setError);
                  })(e);
                }}
              >
                <Controller
                  control={methods.control}
                  name="phrase"
                  render={({ field, fieldState }): React.ReactElement => (
                    <MnemonicInput
                      fullWidth
                      dataQa="phrase"
                      errorMessage={fieldState.error?.message}
                      label="Your recovery phrase"
                      placeholder="Enter each word separated by a space"
                      words={24}
                      {...field}
                    />
                  )}
                />
              </form>
            </FormProvider>
            <SceneParagraph>
              Lost recovery phrase? <GetInTouchLink text="Get in touch." />
            </SceneParagraph>
          </Stack>
        </SceneMain>
      </AppMain>
      <AppFooter>
        <PrimaryButton
          data-qa="submit-phrase-btn"
          disabled={!methods.formState.isValid}
          form={formId}
          loading={methods.formState.isSubmitting}
          type="submit"
        >
          Continue
        </PrimaryButton>
      </AppFooter>
    </AppContainer>
  );
}
