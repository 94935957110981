import { Fragment } from 'react';
import { useUserInitUi } from '@noah-labs/fe-shared-feature-user';
import { GetInTouchLink, LoadingPage } from '@noah-labs/fe-shared-ui-components';
import {
  CompleteFailedScene,
  CompletePendingScene,
  CompleteSuccessScene,
} from '@noah-labs/fe-shared-ui-kyc';
import { KycApprovalStatus } from '@noah-labs/shared-schema-gql';

export function Complete(): React.ReactElement {
  const { data: userData } = useUserInitUi();

  if (!userData) {
    return <LoadingPage message="Retrieving your verification result..." />;
  }

  switch (userData.userProfile.KycVerification.ApprovalStatus) {
    case KycApprovalStatus.Approved:
      return <CompleteSuccessScene />;
    case KycApprovalStatus.Declined:
      return (
        <CompleteFailedScene
          reason={
            <Fragment>
              Unfortunately, you did not succeed in passing document verification. Please{' '}
              <GetInTouchLink /> for futher information.
            </Fragment>
          }
        />
      );
    case KycApprovalStatus.Pending:
    default:
      return <CompletePendingScene />;
  }
}
