import { useEffect } from 'react';
import { TpFeExtraFeature } from '@noah-labs/fe-shared-data-access-user';
import {
  findDepositAddress,
  useAccountCreateMutation,
} from '@noah-labs/fe-shared-data-access-wallet';
import { KycAccessControlData, KycReminderOrigin } from '@noah-labs/fe-shared-feature-kyc';
import { useSardineFlow } from '@noah-labs/fe-shared-feature-sardine';
import { useIsFeatureEnabled } from '@noah-labs/fe-shared-feature-user';
import { Switch404, useStateMachine } from '@noah-labs/fe-shared-ui-components';
import type { TpPaymentCard, TpPaymentMethod } from '@noah-labs/fe-shared-ui-shared';
import { TpAuthStatus, useWalletParams } from '@noah-labs/fe-shared-ui-shared';
import { useWalletError } from '@noah-labs/fe-shared-ui-wallet';
import { walletRoutes } from '@noah-labs/fe-shared-util-routes';
import { getEnvNetwork } from '@noah-labs/shared-currencies';
import { CurrencyUnit, Feature, Network, SardineFlows } from '@noah-labs/shared-schema-gql';
import { Route } from 'react-router-dom';
import { isProd } from '../../../../webConfigBrowser';
import { AccessControlData } from '../../../auth/AccessControlData';
import { Complete } from './Complete';
import { Confirm } from './Confirm';
import { EnterAmount } from './EnterAmount';
import { EnterPaymentInfo } from './EnterPaymentInfo';

export type StBuyRouter = {
  cryptoAmount: string;
  cryptoUnit: CurrencyUnit;
  fetchedAt: string;
  fiatAmount: string;
  paymentMethod?: TpPaymentMethod;
  selectedPaymentCard?: TpPaymentCard;
  transactionId?: string;
};

const emptyState: StBuyRouter = {
  cryptoAmount: '',
  cryptoUnit: CurrencyUnit.Default,
  fetchedAt: '',
  fiatAmount: '',
  paymentMethod: undefined,
  selectedPaymentCard: undefined,
  transactionId: undefined,
};

export function BuyRouter(): React.ReactElement {
  useSardineFlow({ flow: SardineFlows.CheckoutBuy });
  const { AccountType, cryptoCurrency } = useWalletParams();
  const isDepositEnabled = useIsFeatureEnabled(Feature.Deposit);

  const {
    data: accountCreateData,
    error: accountCreateErr,
    isIdle: accountCreateIdle,
    mutate: accountCreate,
  } = useAccountCreateMutation();

  const address = findDepositAddress(accountCreateData?.accountCreate);

  const initialState = { ...emptyState };

  const sm = useStateMachine<StBuyRouter>({
    emptyState,
    initialState,
    name: 'BuyRouter',
  });

  useEffect(() => {
    // If accountCreate has run or there is already an address or the deposit feature is disabled, don't do anything
    if (!accountCreateIdle || address || !isDepositEnabled) {
      return;
    }
    // Otherwise, create a new account
    accountCreate({
      Input: {
        AccountType,
        CurrencyCode: cryptoCurrency.code,
        DepositAddressRequest: {
          Create: true,
          Network: getEnvNetwork(Network.Bitcoin, isProd),
        },
      },
    });
  }, [AccountType, cryptoCurrency, accountCreate, accountCreateIdle, address, isDepositEnabled]);

  const { ApiErrorScene } = useWalletError(accountCreateErr);
  if (ApiErrorScene) {
    return ApiErrorScene;
  }

  return (
    <Switch404>
      <Route
        exact
        path={[
          walletRoutes().buy.base,
          walletRoutes().buy.enterAmount,
          walletRoutes().buy.payment,
          walletRoutes().buy.confirm,
        ]}
      >
        <AccessControlData
          needsAuthStatus={[TpAuthStatus.authenticated]}
          needsFeature={[TpFeExtraFeature.BuyRoutes]}
        >
          <Route exact path={walletRoutes().buy.enterAmount}>
            <EnterAmount {...sm} />
          </Route>

          {/* Keep EnterPaymentInfo in the DOM, but hidden, on all the routes in the buy flow to ensure Frames card fields are preloaded */}
          <EnterPaymentInfo {...sm} />

          <Route exact path={walletRoutes().buy.confirm}>
            <KycAccessControlData origin={KycReminderOrigin.Buy}>
              <Confirm {...sm} />
            </KycAccessControlData>
          </Route>
        </AccessControlData>
      </Route>

      <Route exact path={walletRoutes().buy.complete}>
        <AccessControlData
          needsAuthStatus={[TpAuthStatus.authenticated]}
          needsFeature={[Feature.Buy]}
        >
          <KycAccessControlData origin={KycReminderOrigin.Buy}>
            <Complete {...sm} />
          </KycAccessControlData>
        </AccessControlData>
      </Route>
    </Switch404>
  );
}
