import { useCallback, useEffect, useMemo, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Stack } from '@mui/material';
import {
  AppFooter,
  AppHeader,
  AppMain,
  InputField,
  PrimaryButton,
  SceneHeader,
  SceneMain,
  SceneParagraph,
  SceneTitleLarge,
} from '@noah-labs/fe-shared-ui-components';
import { authRoutes } from '@noah-labs/fe-shared-util-routes';
import type { ErrorOption, Resolver, UseFormSetError } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import { ResendVerificationCode } from '../components/ResendVerificationCode';
import { useAuthError } from '../hooks/useAuthError';

export type TpVerifyAccountForm = {
  root?: { serverError: void };
  verificationCode: string;
};

export type TpResendCode = {
  root?: { serverError: void };
};

const verifyAccountFormId = 'verify-account-form';

const defaultValues: TpVerifyAccountForm = { verificationCode: '' };

const schema = z.object({
  verificationCode: z.string().min(1, 'Verification code is a required field'),
});

export type PpVerifyEmailEnterCodeScene = {
  code: string | null;
  email: string;
  error: ErrorOption | undefined;
  helpButton?: React.ReactNode;
  loading: boolean;
  onResend: (email: string, setError: UseFormSetError<TpResendCode>) => Promise<void>;
  onVerify: (
    values: TpVerifyAccountForm,
    setError: UseFormSetError<TpVerifyAccountForm>,
  ) => Promise<void>;
};

export function VerifyEmailEnterCodeScene({
  code,
  email,
  error,
  helpButton,
  loading,
  onResend,
  onVerify,
}: PpVerifyEmailEnterCodeScene): React.ReactElement {
  const [isResending, setIsResending] = useState(false);

  const values = useMemo(
    () => ({
      verificationCode: code || defaultValues.verificationCode,
    }),
    [code],
  );

  const methods = useForm<TpVerifyAccountForm>({
    defaultValues,
    mode: 'onBlur',
    resolver: zodResolver(schema) as Resolver<TpVerifyAccountForm>,
    values,
  });

  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    setError,
  } = methods;

  useAuthError({ error: errors.root?.serverError });

  useEffect(() => {
    if (!error) {
      return;
    }
    setError('root.serverError', error);
  }, [setError, error]);

  const onResendCallback = useCallback(async () => {
    try {
      setIsResending(true);
      await onResend(email, setError);
    } finally {
      setIsResending(false);
    }
  }, [email, onResend, setError]);

  return (
    <FormProvider {...methods}>
      <AppMain>
        <AppHeader backTo={authRoutes.signOut} endIconsSlot={helpButton} />
        <SceneHeader>
          <SceneTitleLarge>Verify your email</SceneTitleLarge>
          <SceneParagraph>
            To verify your email, we’ve sent a verification code to {email}.
          </SceneParagraph>
        </SceneHeader>
        <SceneMain dataQa="verify-account">
          <Stack justifyContent="center" spacing={4}>
            <form id={verifyAccountFormId} onSubmit={handleSubmit((v) => onVerify(v, setError))}>
              <InputField
                fullWidth
                required
                dataQa="verification-code"
                inputProps={{
                  'aria-label': 'Verification code',
                  inputMode: 'numeric',
                }}
                label="Verification code"
                name="verificationCode"
                placeholder=""
              />
            </form>
            <ResendVerificationCode loading={isResending} onResend={onResendCallback} />
          </Stack>
        </SceneMain>
      </AppMain>
      <AppFooter wide>
        <PrimaryButton
          color="primaryBrand"
          disabled={isSubmitting || loading}
          form={verifyAccountFormId}
          loading={isSubmitting || loading}
          type="submit"
        >
          Continue
        </PrimaryButton>
      </AppFooter>
    </FormProvider>
  );
}
