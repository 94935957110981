import { useState } from 'react';
import { Switch404 } from '@noah-labs/fe-shared-ui-components';
import { TpAuthStatus } from '@noah-labs/fe-shared-ui-shared';
import { userRoutes, walletRoutes } from '@noah-labs/fe-shared-util-routes';
import type { SafeWrapper } from '@noah-labs/shared-cryptography';
import { Feature } from '@noah-labs/shared-schema-gql';
import { Route } from 'react-router-dom';
import { AccessControlData } from '../auth/AccessControlData';
import { CreatePinRecover } from './controllers/CreatePinRecover';
import { EnterCurrentPin } from './controllers/EnterCurrentPin';
import { PinSuccessScene } from './scenes/PinSuccess';

/**
 * Users attempt to update their security PIN using its current PIN.
 */
export function UpdatePinRouter(): React.ReactElement {
  const [mnemonic, setMnemonic] = useState<SafeWrapper<string>>();

  return (
    <Switch404>
      <Route path={userRoutes.pin.update.currentPin}>
        <AccessControlData
          needsAuthStatus={[TpAuthStatus.authenticated]}
          needsFeature={[Feature.Pin]}
        >
          <EnterCurrentPin setMnemonic={setMnemonic} successTo={userRoutes.pin.update.pin} />
        </AccessControlData>
      </Route>
      <Route path={userRoutes.pin.update.pin}>
        <AccessControlData
          needsAuthStatus={[TpAuthStatus.authenticated]}
          needsFeature={[Feature.Pin]}
        >
          <CreatePinRecover
            exitTo={userRoutes.settings.securityPin}
            pageTitle="Update PIN"
            phrase={mnemonic}
            sceneConfirmTitle="Confirm your new security PIN"
            sceneTitle="Create a new security PIN"
            successTo={userRoutes.pin.update.success}
          />
        </AccessControlData>
      </Route>

      <Route path={userRoutes.pin.update.success}>
        <AccessControlData
          needsAuthStatus={[TpAuthStatus.authenticated]}
          needsFeature={[Feature.Pin]}
        >
          <PinSuccessScene
            pageTitle="PIN Updated"
            sceneDescription="You can now use your new PIN to verify transactions for added security."
            sceneTitle="PIN updated sucessfully"
            successTo={walletRoutes().base}
          />
        </AccessControlData>
      </Route>
    </Switch404>
  );
}
