import React from 'react';
import { ChevronRightIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/ChevronRightIcon';
import {
  AppContainer,
  AppHeaderTitle,
  AppMain,
  List,
  ListItem,
  ListItemButton,
  ListItemCard,
  ListItemContent,
  ListItemEndContent,
  ListItemPrimaryText,
  ListItemSecondaryText,
  ListItemStartContent,
  SceneMain,
} from '@noah-labs/fe-shared-ui-components';
import { userRoutes } from '@noah-labs/fe-shared-util-routes';
import { Helmet } from 'react-helmet';
import { AppHeaderData } from '../../../components/layout/AppHeaderData';

type PpSecurityPinScene = {
  backTo?: string;
  pinSetupRequired: boolean;
};

export function SecurityPinScene({
  backTo,
  pinSetupRequired,
}: PpSecurityPinScene): React.ReactElement {
  return (
    <AppContainer>
      <Helmet>
        <title>Security PIN</title>
      </Helmet>
      <AppMain>
        <AppHeaderData helpButton backTo={backTo}>
          <AppHeaderTitle>Security PIN</AppHeaderTitle>
        </AppHeaderData>
        <SceneMain dense>
          <List disablePadding spacing={1}>
            {pinSetupRequired && (
              <ListItem>
                <ListItemButton href={userRoutes.pin.create.pin}>
                  <ListItemCard withHoverEffect>
                    <ListItemContent>
                      <ListItemStartContent>
                        <ListItemPrimaryText>Create PIN</ListItemPrimaryText>
                        <ListItemSecondaryText>Create a new security PIN</ListItemSecondaryText>
                      </ListItemStartContent>
                      <ListItemEndContent>
                        <ChevronRightIcon fontSize="small" />
                      </ListItemEndContent>
                    </ListItemContent>
                  </ListItemCard>
                </ListItemButton>
              </ListItem>
            )}

            {!pinSetupRequired && (
              <React.Fragment>
                <ListItem>
                  <ListItemButton href={userRoutes.pin.update.currentPin}>
                    <ListItemCard withHoverEffect>
                      <ListItemContent>
                        <ListItemStartContent>
                          <ListItemPrimaryText>Update PIN</ListItemPrimaryText>
                          <ListItemSecondaryText>
                            You will be asked for your current PIN
                          </ListItemSecondaryText>
                        </ListItemStartContent>
                        <ListItemEndContent>
                          <ChevronRightIcon fontSize="small" />
                        </ListItemEndContent>
                      </ListItemContent>
                    </ListItemCard>
                  </ListItemButton>
                </ListItem>
                <ListItem>
                  <ListItemButton>
                    <ListItemCard withHoverEffect>
                      <ListItemContent>
                        <ListItemStartContent>
                          <ListItemPrimaryText>Forgot PIN</ListItemPrimaryText>
                          <ListItemSecondaryText>
                            Use your recovery phrase to reset your PIN
                          </ListItemSecondaryText>
                        </ListItemStartContent>
                        <ListItemEndContent>
                          <ChevronRightIcon fontSize="small" />
                        </ListItemEndContent>
                      </ListItemContent>
                    </ListItemCard>
                  </ListItemButton>
                </ListItem>
              </React.Fragment>
            )}
          </List>
        </SceneMain>
      </AppMain>
    </AppContainer>
  );
}
