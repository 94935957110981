import type {
  BooleanMap,
  Country,
  CountryCode,
  FeatureRestrictedCountryRegionMap,
} from '@noah-labs/shared-schema-gql';
import type { TpCountryNeeds } from './filter-functions';
import { getfilterCountryForFeature } from './filter-functions';
import type { TpCountryFilterFunction, TpCountryWithCode } from './types';

let cachedCountries: Array<TpCountryWithCode> | undefined;

export async function getCountryData(): Promise<Array<TpCountryWithCode>> {
  if (cachedCountries) {
    return cachedCountries;
  }
  const countriesData = await import('./countries-list');
  cachedCountries = countriesData.default.reduce((acc: TpCountryWithCode[], countryItem) => {
    if (countryItem.emoji) {
      acc.push({
        ...countryItem,
        code: countryItem.alpha2 as CountryCode,
      });
    }
    return acc;
  }, []);
  return cachedCountries;
}

export async function countryFromCode(code: CountryCode): Promise<Country | undefined> {
  const countries = await getCountryData();
  return countries.find((country) => country.code === code);
}

export function getFilterCountryForFeatureData(
  needs: TpCountryNeeds,
  prohibitedCountries: BooleanMap,
  restrictedCountries: FeatureRestrictedCountryRegionMap,
): TpCountryFilterFunction {
  return getfilterCountryForFeature({
    needs,
    prohibitedCountries,
    restrictedCountries,
  });
}
