import React from 'react';
import { TpFeExtraFeature } from '@noah-labs/fe-shared-data-access-user';
import { KycAccessControlData, KycReminderOrigin } from '@noah-labs/fe-shared-feature-kyc';
import {
  DepositAccountController,
  DepositNetworkController,
  LightningReceiveEnterAmountController,
  LightningReceiveViewInvoiceController,
  ReceiveController,
} from '@noah-labs/fe-shared-feature-wallet';
import type { SmDeposit } from '@noah-labs/fe-shared-feature-wallet';
import {
  AppContainer,
  AppHeaderTitle,
  generatePath,
  Switch404,
  useStateMachine,
} from '@noah-labs/fe-shared-ui-components';
import { TpAuthStatus, useRouter } from '@noah-labs/fe-shared-ui-shared';
import { walletRoutes } from '@noah-labs/fe-shared-util-routes';
import { Feature } from '@noah-labs/shared-schema-gql';
import { matchPath, Route } from 'react-router-dom';
import { AppHeaderData } from '../../../../components/layout/AppHeaderData';
import { AccessControlData } from '../../../auth/AccessControlData';

const emptyState: SmDeposit = {
  fiatAmount: '',
  paymentRequest: '',
  publicID: '',
};

export function ReceiveRouter(): React.ReactElement {
  const { pathname } = useRouter();
  const { params } =
    matchPath(pathname, {
      path: walletRoutes().receive.lightning.viewInvoice,
    }) || {};

  const sm = useStateMachine<SmDeposit>({
    emptyState,
    name: 'Deposit',
  });

  const viewInvoiceInvalid = !sm.state.paymentRequest;

  return (
    <Switch404>
      <Route exact path={walletRoutes().receive.base}>
        <AccessControlData needsAuthStatus={[TpAuthStatus.authenticated]}>
          <KycAccessControlData origin={KycReminderOrigin.Deposit}>
            <AppContainer headTitle="Select Account">
              <AppHeaderData backButton exitButton helpButton />
              <DepositAccountController />
            </AppContainer>
          </KycAccessControlData>
        </AccessControlData>
      </Route>

      <Route exact path={walletRoutes().receive.network}>
        <AccessControlData
          needsAuthStatus={[TpAuthStatus.authenticated]}
          needsFeature={[TpFeExtraFeature.DepositRoutes]}
        >
          <AppContainer headTitle="Select Network">
            <AppHeaderData backButton exitButton helpButton />
            <DepositNetworkController />
          </AppContainer>
        </AccessControlData>
      </Route>

      <Route exact path={walletRoutes().receive.viewAddress}>
        <AccessControlData needsAuthStatus={[TpAuthStatus.authenticated]}>
          <AppContainer headTitle="Receive">
            <AppHeaderData backButton disableFade helpButton>
              <AppHeaderTitle>Receive</AppHeaderTitle>
            </AppHeaderData>
            <ReceiveController />
          </AppContainer>
        </AccessControlData>
      </Route>

      <Route exact path={walletRoutes().receive.lightning.enterAmount}>
        <AccessControlData
          needsAuthStatus={[TpAuthStatus.authenticated]}
          needsFeature={[Feature.LnInvoice]}
        >
          <AppContainer headTitle="Enter Request Amount">
            <AppHeaderData backButton exitButton helpButton>
              <AppHeaderTitle>Enter Request Amount</AppHeaderTitle>
            </AppHeaderData>
            <LightningReceiveEnterAmountController {...sm} />
          </AppContainer>
        </AccessControlData>
      </Route>

      <Route exact path={walletRoutes().receive.lightning.viewInvoice}>
        <AccessControlData
          // redirect to the beginning of the flow if the payment request has not yet been generated
          needsAuthStatus={[TpAuthStatus.authenticated]}
          needsFeature={[Feature.LnInvoice]}
          redirectInvalid={
            viewInvoiceInvalid &&
            params &&
            generatePath(walletRoutes().receive.lightning.enterAmount, params)
          }
        >
          <AppContainer headTitle="Invoice">
            <AppHeaderData exitButton helpButton>
              <AppHeaderTitle>Invoice</AppHeaderTitle>
            </AppHeaderData>
            <LightningReceiveViewInvoiceController {...sm} />
          </AppContainer>
        </AccessControlData>
      </Route>
    </Switch404>
  );
}
