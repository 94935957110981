import { useEffect, useMemo } from 'react';
import { useAuth } from '@noah-labs/fe-shared-feature-auth';
import { useUserInitUi } from '@noah-labs/fe-shared-feature-user';
import { useSearchParams } from '@noah-labs/fe-shared-ui-components';
import { checkAuthStatus, TpAuthStatus } from '@noah-labs/fe-shared-ui-shared';
import { authRoutes, userRoutes, walletRoutes } from '@noah-labs/fe-shared-util-routes';
import { logger } from '@noah-labs/shared-logger/browser';
import { isUndefinedOrNull } from '@noah-labs/shared-util-vanilla';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { webConfigBrowser } from '../webConfigBrowser';

type TpUseInitialRedirect = {
  ready: boolean;
};
export function useInitialRedirect(): TpUseInitialRedirect {
  const { authStatus } = useAuth();
  const { data: userData, isFetched: userInitIsFetched } = useUserInitUi();
  const searchParams = useSearchParams();
  const history = useHistory<{ from: string } | undefined>();
  const referralCode = searchParams?.get(webConfigBrowser.settings.referralCodeParam) ?? null;

  // get route matches
  const isRootRoute = useRouteMatch({ exact: true, path: '/' });
  const isAuthRoute = useRouteMatch(authRoutes.base);

  const [ready, initialRedirect] = useMemo(() => {
    if (checkAuthStatus({ has: authStatus, needs: [TpAuthStatus.guest] }) && referralCode) {
      logger.debug('guest user has referral code, redirect them to signup');
      return [true, authRoutes.signUp];
    }

    if (isAuthRoute) {
      logger.debug("don't redirect from auth routes, AccessControl will handle this");
      return [true];
    }

    if (
      checkAuthStatus({
        has: authStatus,
        needs: [[TpAuthStatus.guest, TpAuthStatus.authenticatedNotVerified]],
      })
    ) {
      logger.debug('guest user, redirect to the signin page');
      return [true, { pathname: authRoutes.signIn, search: history.location.search }];
    }

    if (checkAuthStatus({ has: authStatus, needs: [TpAuthStatus.unknown] }) || !userInitIsFetched) {
      return [false];
    }

    // because userInit isFetched, authStatus must be TpAuthStatus.authenticated at this point
    if (!userData?.userProfile.UsernameDisplay) {
      logger.debug('userprofile is missing a UsernameDisplay, redirect to username screen');
      return [true, userRoutes.username];
    }

    if (isUndefinedOrNull(userData.userSettingsConsent?.EmailContact)) {
      logger.debug('userprofile is missing a EmailContact prefences, redirect to consent screen');
      return [true, userRoutes.consent];
    }

    if (isRootRoute) {
      logger.debug('redirecting full user to the wallet home');
      return [true, walletRoutes().base];
    }

    logger.debug('no initial redirect needed');
    return [true];
  }, [
    authStatus,
    history.location.search,
    isAuthRoute,
    isRootRoute,
    referralCode,
    userData?.userProfile.UsernameDisplay,
    userData?.userSettingsConsent?.EmailContact,
    userInitIsFetched,
  ]);

  useEffect(() => {
    if (!ready || !initialRedirect) {
      return;
    }
    history.replace(initialRedirect);
    // have to be careful not to pass any objects or refs that can be recreated here, or we get a loop
  }, [history, ready, initialRedirect]);
  return { ready };
}
