import { useUserInitUi } from '@noah-labs/fe-shared-feature-user';
import { generatePath } from '@noah-labs/fe-shared-ui-components';
import { getAvailableCryptoNetworks, useWalletParams } from '@noah-labs/fe-shared-ui-shared';
import { SelectNetworkScene } from '@noah-labs/fe-shared-ui-wallet';
import { walletRoutes } from '@noah-labs/fe-shared-util-routes';
import { Feature } from '@noah-labs/shared-schema-gql';

export function DepositNetworkController(): React.ReactElement {
  const { cryptoCurrency, params } = useWalletParams();
  const { data: userData } = useUserInitUi();

  const depositFF = userData?.userProfile.features.get(Feature.Deposit);
  const cryptoNetworks = getAvailableCryptoNetworks(cryptoCurrency.code, depositFF);

  return (
    <SelectNetworkScene
      action="receive"
      cryptoNetworks={cryptoNetworks}
      getSelectedNetworkPath={(network): string =>
        generatePath(walletRoutes().receive.viewAddress, { ...params, network: network.id })
      }
    />
  );
}
