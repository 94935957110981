import { useState } from 'react';
import { Switch404 } from '@noah-labs/fe-shared-ui-components';
import { TpAuthStatus } from '@noah-labs/fe-shared-ui-shared';
import { userRoutes, walletRoutes } from '@noah-labs/fe-shared-util-routes';
import type { SafeWrapper } from '@noah-labs/shared-cryptography';
import { Feature } from '@noah-labs/shared-schema-gql';
import { Route } from 'react-router-dom';
import { AccessControlData } from '../auth/AccessControlData';
import { CreatePin } from './controllers/CreatePin';
import { SecretPhrase } from './controllers/SecretPhrase';
import { PinSuccessScene } from './scenes/PinSuccess';

/**
 * Used when a pin setup is required, i.e. the secret document is either missing or revoked
 * The user will be shown a new secret phrase they need to backup.
 */
export function CreatePinRouter(): React.ReactElement {
  const [phrase, setPhrase] = useState<SafeWrapper<string>>();

  return (
    <Switch404>
      <Route path={userRoutes.pin.create.pin}>
        <AccessControlData
          needsAuthStatus={[TpAuthStatus.authenticated]}
          needsFeature={[Feature.Pin]}
        >
          <CreatePin
            backTo={userRoutes.settings.securityPin}
            setPhrase={setPhrase}
            successTo={userRoutes.pin.create.secretPhrase}
          />
        </AccessControlData>
      </Route>

      <Route path={userRoutes.pin.create.secretPhrase}>
        <AccessControlData
          needsAuthStatus={[TpAuthStatus.authenticated]}
          needsFeature={[Feature.Pin]}
        >
          <SecretPhrase phrase={phrase} successTo={userRoutes.pin.create.success} />
        </AccessControlData>
      </Route>

      <Route path={userRoutes.pin.create.success}>
        <AccessControlData
          needsAuthStatus={[TpAuthStatus.authenticated]}
          needsFeature={[Feature.Pin]}
        >
          <PinSuccessScene
            pageTitle="PIN Created"
            sceneDescription="You can now use your new PIN to verify transactions for added security."
            sceneTitle="New PIN created successfully"
            successTo={walletRoutes().base}
          />
        </AccessControlData>
      </Route>
    </Switch404>
  );
}
