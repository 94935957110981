import { useUserLimitsQuery } from '@noah-labs/fe-shared-data-access-user';
import {
  getLnSendLimitAndUsage,
  getWithdrawalDayLimitAndUsage,
  unlimited,
} from '@noah-labs/fe-shared-data-access-wallet';
import { cryptoNetworkEnvFromId } from '@noah-labs/fe-shared-ui-shared';
import { Network, PolicyPeriod } from '@noah-labs/shared-schema-gql';
import { isProd } from '../../../webConfigBrowser';
import type { PpAccountLimitsScene } from '../scenes/AccountLimits';
import { AccountLimitsScene } from '../scenes/AccountLimits';

export function AccountLimits(): React.ReactElement {
  const { data: limitsAndUsageData } = useUserLimitsQuery(undefined, {
    select: (data): PpAccountLimitsScene => ({
      accountDepositDay: unlimited,
      accountWithdrawDay: getWithdrawalDayLimitAndUsage(data.userLimit.Limits),
      btcWithdrawTxsDay: getWithdrawalDayLimitAndUsage(
        data.userLimit.Limits,
        cryptoNetworkEnvFromId(Network.Bitcoin, isProd),
      ).count,
      ethWithdrawTxsDay: getWithdrawalDayLimitAndUsage(
        data.userLimit.Limits,
        cryptoNetworkEnvFromId(Network.Ethereum, isProd),
      ).count,
      lnReceiveDay: unlimited,
      lnReceiveSingle: unlimited,
      lnSendDay: getLnSendLimitAndUsage(data.userLimit.Limits, PolicyPeriod.DAY),
      lnSendSingle: getLnSendLimitAndUsage(data.userLimit.Limits, PolicyPeriod.SINGLE),
    }),
  });

  const props = limitsAndUsageData || {
    accountDepositDay: undefined,
    accountWithdrawDay: undefined,
    btcWithdrawTxsDay: undefined,
    ethWithdrawTxsDay: undefined,
    lnReceiveDay: undefined,
    lnReceiveSingle: undefined,
    lnSendDay: undefined,
    lnSendSingle: undefined,
  };

  return <AccountLimitsScene {...props} />;
}
