import { useEffect, useState } from 'react';
import {
  AddressManualController,
  AddressScanController,
  useAddress,
} from '@noah-labs/fe-shared-feature-address';
import { generatePath, Switch404 } from '@noah-labs/fe-shared-ui-components';
import { TpAuthStatus, useWalletParams } from '@noah-labs/fe-shared-ui-shared';
import { walletRoutes } from '@noah-labs/fe-shared-util-routes';
import { Route } from 'react-router-dom';
import { useBackHijack } from '../../../../hooks/useBackHijack';
import { AccessControlData } from '../../../auth/AccessControlData';
import { WalletControllerWrapper } from '../../components/layout/WalletControllerWrapper';
import { AddressScanWrapper } from './AddressScanWrapper';

export function AddressRouter(): React.ReactElement {
  const { params } = useWalletParams();
  const [appHeaderHeight, setAppHeaderHeight] = useState(0);

  const { scannerUnavailable, setData } = useAddress();

  const { backTo, goBack } = useBackHijack(walletRoutes().send.base);

  useEffect(() => () => setData(undefined), [setData]);

  return (
    <Switch404>
      <Route exact path={walletRoutes().send.address.scan}>
        <AccessControlData
          needsAuthStatus={[TpAuthStatus.authenticated]}
          redirectInvalid={
            scannerUnavailable && generatePath(walletRoutes().send.address.base, params)
          }
        >
          <AddressScanWrapper setAppHeaderHeight={setAppHeaderHeight}>
            <AddressScanController appHeaderHeight={appHeaderHeight} />
          </AddressScanWrapper>
        </AccessControlData>
      </Route>
      <Route exact path={walletRoutes().send.address.base}>
        <AccessControlData needsAuthStatus={[TpAuthStatus.authenticated]}>
          <WalletControllerWrapper
            backButton={goBack}
            backTo={backTo}
            headerTitle="Enter Address"
            headTitle="Enter Address"
          >
            <AddressManualController />
          </WalletControllerWrapper>
        </AccessControlData>
      </Route>
    </Switch404>
  );
}
