/* eslint-disable react/jsx-no-useless-fragment */
import type { ReactElement } from 'react';
import { Fragment } from 'react';
import {
  useFeature,
  useUserAvailableFiatBalance,
  useUserInitUi,
} from '@noah-labs/fe-shared-feature-user';
import { LoadingPage } from '@noah-labs/fe-shared-ui-components';
import type { PpWC } from '@noah-labs/fe-shared-ui-shared';
import { kycRoutes as routes } from '@noah-labs/fe-shared-util-routes';
import { Feature, KycApprovalStatus } from '@noah-labs/shared-schema-gql';
import { Redirect } from 'react-router-dom';
import type { KycReminderOrigin } from './Reminder';

type PpNeedsKyc = PpWC & {
  bypassIfUserHasBalance?: boolean;
  origin?: KycReminderOrigin;
};

export function KycAccessControlData({
  bypassIfUserHasBalance,
  children,
  origin,
}: PpNeedsKyc): ReactElement | null {
  const { data: userData } = useUserInitUi();
  const userAvailableBalance = useUserAvailableFiatBalance(false);

  /**
   * Business customer, assume they are KYC'd
   */
  const { feature: fBusiness } = useFeature(Feature.Business);
  if (fBusiness?.Enabled) {
    return <Fragment>{children}</Fragment>;
  }

  if (!userData || !userAvailableBalance.isFetched) {
    return <LoadingPage />;
  }

  const userHasBalance = Number(userAvailableBalance.availableBalance) > 0;
  if (bypassIfUserHasBalance && userHasBalance) {
    return <Fragment>{children}</Fragment>;
  }

  const reminderPath = origin
    ? {
        pathname: routes.reminder,
        search: new URLSearchParams({ origin }).toString(),
      }
    : routes.reminder;

  if (userData.userProfile.KycVerification.ApprovalStatus !== KycApprovalStatus.Approved) {
    return <Redirect to={reminderPath} />;
  }

  return <Fragment>{children}</Fragment>;
}
