import { useUserInitUi } from '@noah-labs/fe-shared-feature-user';
import { useInAppReviewTrigger } from '@noah-labs/fe-shared-feature-wallet';
import { Balance, getCurrencyAmountSlots } from '@noah-labs/fe-shared-ui-components';
import type { TpStateMachine } from '@noah-labs/fe-shared-ui-components';
import { useWalletParams } from '@noah-labs/fe-shared-ui-shared';
import { walletRoutes } from '@noah-labs/fe-shared-util-routes';
import { TpCheckoutPaymentStatus } from '@noah-labs/fe-shared-util-shared';
import { CurrencyUnit } from '@noah-labs/shared-schema-gql';
// eslint-disable-next-line no-restricted-imports
import { generatePath, useParams } from 'react-router-dom';
import { CompleteBuyScene } from '../../scenes/CompleteBuy';
import type { StBuyRouter } from './BuyRouter';

export function Complete({ state }: TpStateMachine<StBuyRouter>): React.ReactElement {
  // TODO: we should pass the txid in the URL and get the real tx data from our API
  // sessionStorage is not reliable enough for this
  const { cryptoCurrency, params } = useWalletParams();
  const { paymentStatus } = useParams<{ paymentStatus: TpCheckoutPaymentStatus }>();
  const { data: userData } = useUserInitUi();

  const enableInAppReviewTrigger = paymentStatus === TpCheckoutPaymentStatus.success;
  useInAppReviewTrigger(enableInAppReviewTrigger);

  const { cryptoAmount, fiatAmount } = state;

  const Amounts = getCurrencyAmountSlots({
    cryptoAmount,
    cryptoCurrency,
    cryptoUnit: CurrencyUnit.Default,
    fiatAmount: fiatAmount === '' ? undefined : fiatAmount,
    fiatCurrency: userData?.userProfile.fiatPaymentCurrency,
    nonZeroCrypto: true,
    primaryCurrency: userData?.userProfile.PrimaryCurrency,
    strikeThrough: paymentStatus === TpCheckoutPaymentStatus.failure,
  });

  let transactionUrl;

  if (typeof state.transactionId === 'string') {
    transactionUrl = generatePath(walletRoutes().account.transactions, {
      ...params,
      id: state.transactionId,
    });
  }

  return (
    <CompleteBuyScene
      BalanceSlot={<Balance {...Amounts} />}
      paymentStatus={paymentStatus}
      transactionUrl={transactionUrl}
    />
  );
}
