import { useCallback } from 'react';
import {
  AvailableBalanceData,
  useUserInitUi,
  useUserWithdrawalAllowance,
} from '@noah-labs/fe-shared-feature-user';
import type { TpStateMachine } from '@noah-labs/fe-shared-ui-components';
import { generatePath } from '@noah-labs/fe-shared-ui-components';
import { getCryptoUnitAmount, useRouter, useWalletParams } from '@noah-labs/fe-shared-ui-shared';
import { EnterAmountScene } from '@noah-labs/fe-shared-ui-wallet';
import { walletRoutes } from '@noah-labs/fe-shared-util-routes';
import type { TpAmountForm } from '@noah-labs/fe-shared-util-validation-schemas';
import { getLnSendSchema } from '@noah-labs/fe-shared-util-validation-schemas';
import { truncateAmount } from '@noah-labs/shared-currencies';
import BigNumber from 'bignumber.js';
import type { SmLightningSend } from '../types';

export function LightningSendEnterAmountController({
  state,
  updateState,
}: TpStateMachine<SmLightningSend>): React.ReactElement {
  const { AccountType, cryptoCurrency, cryptoNetwork, params } = useWalletParams();
  const allowance = useUserWithdrawalAllowance({
    accountType: AccountType,
    cryptoCurrency,
    cryptoNetwork,
  });
  const { data: userData } = useUserInitUi();

  const maxAmounts =
    state.lightningAddressProxy &&
    getCryptoUnitAmount({
      amount: state.lightningAddressProxy.MaxSendable,
      cryptoCurrency,
      cryptoUnit: userData?.userProfile.DisplayUnit,
    });

  const minAmounts =
    state.lightningAddressProxy &&
    getCryptoUnitAmount({
      amount: state.lightningAddressProxy.MinSendable,
      cryptoCurrency,
      cryptoUnit: userData?.userProfile.DisplayUnit,
    });

  const schema = getLnSendSchema({
    allowance: allowance?.lnAllowanceCrypto,
    fiatCurrency: userData?.userProfile.fiatCurrency,
    maxAmounts,
    minAmounts,
  });

  const { push } = useRouter();
  const onSubmit = useCallback(
    (values: TpAmountForm) => {
      updateState({
        ...values,
        cryptoAmount: truncateAmount({
          amount: values.cryptoAmount,
          decimalPlaces: cryptoCurrency.decimals,
          roundingMode: BigNumber.ROUND_DOWN,
        }),
        fiatAmount: truncateAmount({
          amount: values.fiatAmount,
          decimalPlaces: userData?.userProfile.fiatCurrency.decimals,
          roundingMode: BigNumber.ROUND_DOWN,
        }),
      });
      push(generatePath(walletRoutes().send.lightning.confirm, params));
    },
    [
      cryptoCurrency.decimals,
      params,
      push,
      updateState,
      userData?.userProfile.fiatCurrency.decimals,
    ],
  );

  return (
    <EnterAmountScene
      ContentSlot={
        <AvailableBalanceData AccountType={AccountType} cryptoCurrency={cryptoCurrency} />
      }
      cryptoAmount={state.cryptoAmount}
      cryptoCurrency={cryptoCurrency}
      cryptoUnit={userData?.userProfile.DisplayUnit}
      ctaButtonDisabled={!schema}
      fiatAmount={state.fiatAmount}
      fiatCurrency={userData?.userProfile.fiatCurrency}
      priceProvider="market"
      primaryCurrency={userData?.userProfile.PrimaryCurrency}
      zodSchema={schema}
      onSubmit={onSubmit}
    />
  );
}
