import {
  AppContainer,
  AppHeaderTitle,
  AppMain,
  List,
  ListItem,
  ListItemCard,
  ListItemContent,
  ListItemPrimaryText,
  ListItemSecondaryText,
  ListItemStartContent,
  SceneMain,
} from '@noah-labs/fe-shared-ui-components';
import type { TpSkeletonText } from '@noah-labs/fe-shared-ui-shared';
import type { FullName, Maybe } from '@noah-labs/shared-schema-gql';
import { Helmet } from 'react-helmet';
import { AppHeaderData } from '../../../components/layout/AppHeaderData';

export type PpProfileDetailsScene = {
  email: TpSkeletonText;
  personalName?: Maybe<FullName>;
};
export function ProfileDetailsScene({
  email,
  personalName,
}: PpProfileDetailsScene): React.ReactElement {
  const name = personalName
    ? [personalName.FirstName, personalName.MiddleName, personalName.LastName]
        .filter(Boolean)
        .join(' ')
    : '-';

  return (
    <AppContainer>
      <Helmet>
        <title>Personal details</title>
      </Helmet>
      <AppMain>
        <AppHeaderData backButton helpButton>
          <AppHeaderTitle>Personal details</AppHeaderTitle>
        </AppHeaderData>
        <SceneMain dense dataQa="personal-details-menu">
          <List disablePadding spacing={1.5}>
            <ListItem>
              <ListItemCard>
                <ListItemContent>
                  <ListItemStartContent>
                    <ListItemPrimaryText>Name</ListItemPrimaryText>
                    <ListItemSecondaryText>{name}</ListItemSecondaryText>
                  </ListItemStartContent>
                </ListItemContent>
              </ListItemCard>
            </ListItem>
            <ListItem>
              <ListItemCard>
                <ListItemContent>
                  <ListItemStartContent>
                    <ListItemPrimaryText>Email</ListItemPrimaryText>
                    <ListItemSecondaryText>{email}</ListItemSecondaryText>
                  </ListItemStartContent>
                </ListItemContent>
              </ListItemCard>
            </ListItem>
          </List>
        </SceneMain>
      </AppMain>
    </AppContainer>
  );
}
