import { useEffect, useMemo } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Link, Stack } from '@mui/material';
import {
  AppFooter,
  AppHeader,
  AppMain,
  InputField,
  PrimaryButton,
  SceneHeader,
  SceneMain,
  SceneParagraph,
  SceneTitleLarge,
} from '@noah-labs/fe-shared-ui-components';
import { authRoutes } from '@noah-labs/fe-shared-util-routes';
import type { ErrorOption, Resolver, UseFormSetError } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import { useAuthError } from '../hooks/useAuthError';

export type TpForgottenEmailForm = {
  email: string;
  root?: { serverError: void };
};

export type TpForgottenEmail = {
  root?: { serverError: void };
};

const forgottenEmailFormId = 'forgotten-email-form';

const defaults: TpForgottenEmailForm = { email: '' };

const schema = z.object({
  email: z.string().email().min(1, 'Email is a required field'),
});

export type PpForgottenPasswordEnterEmailScene = {
  email: string | null;
  error: ErrorOption | undefined;
  helpButton?: React.ReactNode;
  loading?: boolean;
  onRecover: (
    values: TpForgottenEmailForm,
    setError: UseFormSetError<TpForgottenEmailForm>,
  ) => Promise<void>;
};

export function ForgottenPasswordEnterEmailScene({
  email,
  error,
  helpButton,
  loading,
  onRecover,
}: PpForgottenPasswordEnterEmailScene): React.ReactElement {
  const defaultValues = useMemo(
    () => ({
      email: email || defaults.email,
    }),
    [email],
  );

  const methods = useForm<TpForgottenEmailForm>({
    defaultValues,
    mode: 'onBlur',
    resolver: zodResolver(schema) as Resolver<TpForgottenEmailForm>,
    values: defaultValues,
  });

  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    setError,
  } = methods;

  useAuthError({ error: errors.root?.serverError });

  useEffect(() => {
    if (!error) {
      return;
    }
    setError('root.serverError', error);
  }, [setError, error]);

  return (
    <FormProvider {...methods}>
      <AppMain>
        <AppHeader backButton endIconsSlot={helpButton} />
        <SceneHeader>
          <SceneTitleLarge>Forgotten your password?</SceneTitleLarge>
          <SceneParagraph>
            We’ll send you an email with your recovery code if your email is registered in our
            system.
          </SceneParagraph>
        </SceneHeader>
        <SceneMain dataQa="forgotten-email">
          <Stack justifyContent="center" spacing={4}>
            <form
              id={forgottenEmailFormId}
              onSubmit={handleSubmit((values) => onRecover(values, setError))}
            >
              <InputField
                fullWidth
                required
                dataQa="email"
                inputProps={{
                  'aria-label': 'Email',
                  inputMode: 'email',
                }}
                label="Email"
                name="email"
                placeholder=""
              />
            </form>
            <SceneParagraph>
              Remembered your password? <Link href={authRoutes.signIn}>Sign in</Link>
            </SceneParagraph>
          </Stack>
        </SceneMain>
      </AppMain>
      <AppFooter wide>
        <PrimaryButton
          color="primaryBrand"
          disabled={isSubmitting || loading}
          form={forgottenEmailFormId}
          loading={isSubmitting || loading}
          type="submit"
        >
          Continue
        </PrimaryButton>
      </AppFooter>
    </FormProvider>
  );
}
