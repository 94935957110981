import React, { useCallback, useRef } from 'react';
import { Stack } from '@mui/material';
import { useApplePay, useGooglePay } from '@noah-labs/fe-shared-data-access-payment-methods';
import { useCalculateFiatFromCrypto } from '@noah-labs/fe-shared-data-access-wallet';
import { useUserInitUi } from '@noah-labs/fe-shared-feature-user';
import type { TpPaymentMethodChange } from '@noah-labs/fe-shared-feature-wallet';
import type { TpStateMachine } from '@noah-labs/fe-shared-ui-components';
import {
  AppContainer,
  AppHeaderSubtitle,
  AppHeaderTitle,
  generatePath,
} from '@noah-labs/fe-shared-ui-components';
import { useRouter, useWalletParams } from '@noah-labs/fe-shared-ui-shared';
import type { TpDialogToggle } from '@noah-labs/fe-shared-ui-shared';
import { PaymentCurrencyDialog } from '@noah-labs/fe-shared-ui-user';
import {
  CryptoPrice,
  CurrencyDialogToggle,
  EnterAmountScene,
} from '@noah-labs/fe-shared-ui-wallet';
import { walletRoutes } from '@noah-labs/fe-shared-util-routes';
import type { TpAmountForm } from '@noah-labs/fe-shared-util-validation-schemas';
import { getCkoBuyAmountSchema } from '@noah-labs/fe-shared-util-validation-schemas';
import { truncateAmount } from '@noah-labs/shared-currencies';
import { CurrencyDisplayType, CurrencyUnit } from '@noah-labs/shared-schema-gql';
import BigNumber from 'bignumber.js';
import { AppHeaderData } from '../../../../components/layout/AppHeaderData';
import { useBackHijack } from '../../../../hooks/useBackHijack';
import { isProd, webConfigBrowser } from '../../../../webConfigBrowser';
import { PaymentMethodsPicker } from '../../components/payment/PaymentMethodsPicker/PaymentMethodsPicker';
import type { StBuyRouter } from './BuyRouter';

const {
  googlePayGateway,
  googlePayMerchantId,
  minimumFiatAmount,
  publicKey: gatewayMerchantId,
} = webConfigBrowser.cko;

type PpEnterAmount = TpStateMachine<StBuyRouter>;

export function EnterAmount({ state, updateState }: PpEnterAmount): React.ReactElement {
  const { data: userData } = useUserInitUi();

  const { isReady: googlePayReady } = useGooglePay({
    countryCode: userData?.userProfile.HomeAddress?.CountryCode,
    environment: isProd ? 'PRODUCTION' : 'TEST',
    gateway: googlePayGateway,
    gatewayMerchantId,
    merchantId: googlePayMerchantId,
  });

  const { isReady: applePayReady } = useApplePay();

  const { cryptoCurrency, params } = useWalletParams();
  const { push } = useRouter();
  const { backTo, goBack } = useBackHijack(generatePath(walletRoutes().base, params));
  const paymentCurrencyDialog = useRef<TpDialogToggle>(null);

  const { data: cryptoData } = useCalculateFiatFromCrypto({
    cryptoAmount: '1',
    cryptoCurrency,
    fiatCurrency: userData?.userProfile.fiatPaymentCurrency,
    priceProvider: 'buy',
  });

  const onPaymentMethodChange = useCallback(
    ({ card, type }: TpPaymentMethodChange): void => {
      updateState({
        paymentMethod: type,
        selectedPaymentCard: card,
      });
    },
    [updateState],
  );

  const onSubmit = useCallback(
    ({ cryptoAmount, fiatAmount }: TpAmountForm) => {
      updateState({
        cryptoAmount: truncateAmount({
          amount: cryptoAmount,
          decimalPlaces: cryptoCurrency.decimals,
          roundingMode: BigNumber.ROUND_DOWN,
        }),
        fiatAmount: truncateAmount({
          amount: fiatAmount,
          decimalPlaces: userData?.userProfile.fiatPaymentCurrency.decimals,
          roundingMode: BigNumber.ROUND_DOWN,
        }),
      });

      push(generatePath(walletRoutes().buy.confirm, params));
    },
    [
      cryptoCurrency.decimals,
      params,
      push,
      updateState,
      userData?.userProfile.fiatPaymentCurrency.decimals,
    ],
  );

  return (
    <AppContainer headTitle="Buy">
      <AppHeaderData exitButton helpButton backButton={goBack} backTo={backTo}>
        <Stack>
          <AppHeaderTitle>Buy {cryptoCurrency.label}</AppHeaderTitle>
          <AppHeaderSubtitle>
            {userData && (
              <CryptoPrice
                cryptoCurrencyCode={cryptoCurrency.code}
                fiatCurrency={userData.userProfile.fiatPaymentCurrency}
                price={cryptoData?.fiatAmount}
              />
            )}
          </AppHeaderSubtitle>
        </Stack>
      </AppHeaderData>
      <EnterAmountScene
        amountRequired
        disableSwitch
        isCryptoAmountNet
        amountPlaceholder={minimumFiatAmount}
        ContentSlot={
          <React.Fragment>
            <PaymentMethodsPicker
              applePayEnabled={applePayReady}
              googlePayEnabled={googlePayReady}
              paymentMethod={state.paymentMethod}
              selectedPaymentCard={state.selectedPaymentCard}
              onChange={onPaymentMethodChange}
              onSubmitCardDetailsRedirect={generatePath(walletRoutes().buy.enterAmount, params)}
            />
            {userData && (
              <PaymentCurrencyDialog
                ref={paymentCurrencyDialog}
                fiatCurrency={userData.userProfile.fiatCurrency.code}
              />
            )}
          </React.Fragment>
        }
        cryptoAmount={state.cryptoAmount}
        cryptoCurrency={cryptoCurrency}
        cryptoUnit={CurrencyUnit.Default}
        ctaButtonDisabled={!state.paymentMethod}
        ctaButtonLabel="Preview buy"
        fiatAmount={state.fiatAmount}
        fiatCurrency={userData?.userProfile.fiatPaymentCurrency}
        priceProvider="buy"
        primaryCurrency={CurrencyDisplayType.Fiat}
        SwitchCurrencySlot={
          <CurrencyDialogToggle onClick={(): void => paymentCurrencyDialog.current?.toggle()} />
        }
        zodSchema={getCkoBuyAmountSchema(
          userData?.userProfile.fiatPaymentCurrency,
          minimumFiatAmount,
        )}
        onBlurValues={updateState}
        onSubmit={onSubmit}
      />
    </AppContainer>
  );
}
