import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import type { SocialProvider } from '@noah-labs/fe-shared-data-access-auth';
import { TpAnalyticsEvent, useAnalytics } from '@noah-labs/fe-shared-feature-analytics';
import { orderedProviders } from '@noah-labs/fe-shared-ui-auth';
import {
  AppContainer,
  AppHeaderTitle,
  AppMain,
  InlineLinkOrButton,
  List,
  ListSection,
  MenuItemSwitch,
  SceneMain,
  usePushAlert,
} from '@noah-labs/fe-shared-ui-components';
import { useRouter } from '@noah-labs/fe-shared-ui-shared';
import type { TpDialogToggle } from '@noah-labs/fe-shared-ui-shared';
import { authRoutes, userRoutes } from '@noah-labs/fe-shared-util-routes';
import { toTitleCase } from '@noah-labs/shared-util-vanilla';
import { Helmet } from 'react-helmet';
import { AppHeaderData } from '../../../components/layout/AppHeaderData';
import { UnlinkLowDialog } from '../components/dialogs/UnlinkLowDialog';

export type PpLinkedAccountsScene = {
  availableProviders: SocialProvider[] | undefined;
  linkedProvidersInitial: SocialProvider[] | undefined;
  onLinkAccount: (provider: SocialProvider) => Promise<void>;
  onUnLinkAccount: (provider: SocialProvider) => Promise<SocialProvider[] | undefined>;
};

export function LinkedAccountsScene({
  availableProviders,
  linkedProvidersInitial,
  onLinkAccount,
  onUnLinkAccount,
}: PpLinkedAccountsScene): React.ReactElement {
  const analytics = useAnalytics();
  const pushAlert = usePushAlert();
  const { push } = useRouter();

  const unlinkDialog = useRef<TpDialogToggle>(null);
  const [loading, setLoading] = useState(false);
  const [linkedProviders, setLinkedProviders] = useState<SocialProvider[] | undefined>(undefined);

  const handleNewPasswordRedirect = useCallback(() => {
    analytics.track(TpAnalyticsEvent.NewPasswordLinkClicked);
    push({
      pathname: authRoutes.newPasswordEntry,
      search: new URLSearchParams({ returnTo: userRoutes.accounts }).toString(),
    });
  }, [analytics, push]);

  const handleAccountLinking = useCallback(
    async (provider: SocialProvider) => {
      try {
        analytics.track(TpAnalyticsEvent.SocialAuthLink, { provider });
        setLoading(true);
        await onLinkAccount(provider);
      } catch (error) {
        if (error instanceof Error) {
          pushAlert({
            dismissable: true,
            key: 'linkingAccountError',
            message: error.message,
            preventDuplicate: true,
            severity: 'error',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [analytics, onLinkAccount, setLoading, pushAlert],
  );

  const handleAccountUnLinking = useCallback(
    async (provider: SocialProvider) => {
      try {
        analytics.track(TpAnalyticsEvent.SocialAuthUnlink, { provider });
        setLoading(true);
        const remainingProviders = await onUnLinkAccount(provider);
        setLinkedProviders(remainingProviders);
      } catch (error) {
        if (error instanceof Error) {
          pushAlert({
            dismissable: true,
            key: 'unLinkingAccountError',
            message: error.message,
            preventDuplicate: true,
            severity: 'error',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [analytics, onUnLinkAccount, setLoading, pushAlert],
  );

  const onToggleLink = useCallback(
    async (provider: SocialProvider) => {
      if (linkedProviders?.includes(provider)) {
        await handleAccountUnLinking(provider);
        return;
      }
      await handleAccountLinking(provider);
    },
    [handleAccountLinking, handleAccountUnLinking, linkedProviders],
  );

  useEffect(() => {
    if (!linkedProvidersInitial) {
      return;
    }
    setLinkedProviders(linkedProvidersInitial);
  }, [linkedProvidersInitial]);

  return (
    <AppContainer>
      <Helmet>
        <title>Linked accounts</title>
      </Helmet>
      <AppMain>
        <AppHeaderData helpButton backTo={userRoutes.settings.base}>
          <AppHeaderTitle>Linked accounts</AppHeaderTitle>
        </AppHeaderData>
        <SceneMain dense dataQa="linked-accounts-menu">
          <Stack spacing={3}>
            <Typography color="text.light" textAlign="center" variant="paragraphBodyS">
              You can use these to quickly log in to your NOAH account
            </Typography>
            <ListSection>
              <List disablePadding spacing={1}>
                {orderedProviders.map((p) => (
                  <Fragment key={p.name}>
                    <MenuItemSwitch
                      checked={linkedProviders?.includes(p.name) ?? false}
                      dataQa={`${p.name}-sign-in-toggle`}
                      disabled={!availableProviders?.includes(p.name) || loading}
                      icon={p.icon}
                      label={`Connected with ${toTitleCase(p.name)}`}
                      onClick={(): Promise<void> => onToggleLink(p.name)}
                    />
                    {availableProviders && !availableProviders.includes(p.name) && (
                      <Typography color="text.light" mb={1} variant="paragraphBodyS">
                        Account linking or unlinking is not possible for this provider.{' '}
                        <InlineLinkOrButton onClick={handleNewPasswordRedirect}>
                          Set up a password to continue.
                        </InlineLinkOrButton>
                      </Typography>
                    )}
                  </Fragment>
                ))}
              </List>
            </ListSection>
          </Stack>
        </SceneMain>
      </AppMain>
      <UnlinkLowDialog ref={unlinkDialog} />
    </AppContainer>
  );
}
