import { Consent } from '@noah-labs/fe-shared-feature-user';
import { AppContainer, Switch404 } from '@noah-labs/fe-shared-ui-components';
import { TpAuthStatus } from '@noah-labs/fe-shared-ui-shared';
import { userRoutes } from '@noah-labs/fe-shared-util-routes';
import { Feature } from '@noah-labs/shared-schema-gql';
import { Route } from 'react-router-dom';
import { AppHeaderData } from '../../components/layout/AppHeaderData';
import { AccessControlData } from '../auth/AccessControlData';
import { AccountLimits } from './controllers/AccountLimits';
import { CurrencyUnitSetting } from './controllers/CurrencyUnitSetting';
import { DisplayCurrencySetting } from './controllers/DisplayCurrencySetting';
import { LinkedAccounts } from './controllers/LinkedAccounts';
import { PaymentCurrencySetting } from './controllers/PaymentCurrencySetting';
import { PrimaryCurrencySetting } from './controllers/PrimaryCurrencySetting';
import { ProfileDetails } from './controllers/ProfileDetails';
import { SecurityPin } from './controllers/SecurityPin';
import { Settings } from './controllers/Settings';
import { TransactionPinThreshold } from './controllers/TransactionPinThreshold';
import { CreatePinRouter } from './CreatePinRouter';
import { PaymentMethodsRouter } from './PaymentMethodsRouter';
import { PinRevocationRouter } from './PinRevocationRouter';
import { RecoverPinWithSecretPhraseRouter } from './RecoverPinWithSecretPhraseRouter';
import { UpdatePinRouter } from './UpdatePinRouter';

export function Router(): React.ReactElement {
  return (
    <Switch404>
      {/* User Settings */}
      <Route exact path={userRoutes.settings.base}>
        <AccessControlData needsAuthStatus={[TpAuthStatus.authenticated]}>
          <Settings />
        </AccessControlData>
      </Route>
      <Route
        exact
        path={[
          userRoutes.settings.newPaymentMethod,
          userRoutes.settings.editPaymentMethod,
          userRoutes.settings.paymentMethods,
        ]}
      >
        <PaymentMethodsRouter />
      </Route>
      <Route exact path={userRoutes.settings.displayCurrency}>
        <AccessControlData needsAuthStatus={[TpAuthStatus.authenticated]}>
          <DisplayCurrencySetting />
        </AccessControlData>
      </Route>

      <Route exact path={userRoutes.settings.paymentCurrency}>
        <AccessControlData needsAuthStatus={[TpAuthStatus.authenticated]}>
          <PaymentCurrencySetting />
        </AccessControlData>
      </Route>

      <Route exact path={userRoutes.settings.bitcoinUnit}>
        <AccessControlData needsAuthStatus={[TpAuthStatus.authenticated]}>
          <CurrencyUnitSetting />
        </AccessControlData>
      </Route>

      <Route exact path={userRoutes.settings.primaryCurrency}>
        <AccessControlData needsAuthStatus={[TpAuthStatus.authenticated]}>
          <PrimaryCurrencySetting />
        </AccessControlData>
      </Route>

      <Route exact path={userRoutes.settings.accountLimits}>
        <AccessControlData needsAuthStatus={[TpAuthStatus.authenticated]}>
          <AccountLimits />
        </AccessControlData>
      </Route>

      {/* Settings Consent Route */}
      <Route exact path={userRoutes.settings.consent}>
        <AccessControlData needsAuthStatus={[TpAuthStatus.authenticated]}>
          <AppContainer headTitle="We value your privacy">
            <AppHeaderData backButton helpButton />
            <Consent
              /**
               * The Segment analytics script needs to be reloaded if there have been any changes so need use a real page navigation
               * This is why we have the nextAction and nextActionDirty functions passed to the Consent controller
               */
              nextActionDirty={(): void => window.location.reload()}
            />
          </AppContainer>
        </AccessControlData>
      </Route>

      <Route exact path={userRoutes.accounts}>
        <AccessControlData needsAuthStatus={[TpAuthStatus.authenticated]}>
          <LinkedAccounts />
        </AccessControlData>
      </Route>

      {/* User Profile */}
      <Route exact path={userRoutes.profile}>
        <AccessControlData needsAuthStatus={[TpAuthStatus.authenticated]}>
          <ProfileDetails />
        </AccessControlData>
      </Route>

      {/* Security pin */}
      <Route exact path={userRoutes.settings.securityPin}>
        <AccessControlData
          needsAuthStatus={[TpAuthStatus.authenticated]}
          needsFeature={[Feature.Pin]}
        >
          <SecurityPin />
        </AccessControlData>
      </Route>

      <Route
        path={[
          userRoutes.pin.create.pin,
          userRoutes.pin.create.secretPhrase,
          userRoutes.pin.create.success,
        ]}
      >
        <CreatePinRouter />
      </Route>

      <Route
        path={[
          userRoutes.pin.reset.secretPhrase,
          userRoutes.pin.reset.pin,
          userRoutes.pin.reset.success,
        ]}
      >
        <RecoverPinWithSecretPhraseRouter />
      </Route>

      <Route
        path={[
          userRoutes.pin.update.currentPin,
          userRoutes.pin.update.pin,
          userRoutes.pin.update.success,
        ]}
      >
        <UpdatePinRouter />
      </Route>

      <Route exact path={userRoutes.settings.securityPinThreshold}>
        <AccessControlData
          needsAuthStatus={[TpAuthStatus.authenticated]}
          needsFeature={[Feature.Pin]}
        >
          <TransactionPinThreshold />
        </AccessControlData>
      </Route>

      <Route path={[userRoutes.pin.revocation.revoke, userRoutes.pin.revocation.unrevoke]}>
        <PinRevocationRouter />
      </Route>
    </Switch404>
  );
}
