import {
  AuthError,
  ForgottenPassword,
  ForgottenPasswordNewPassword,
  ForgottenPasswordWrapper,
  SignedOut,
  SignIn,
  SignOut,
  SignUp,
  VerifyEmail,
} from '@noah-labs/fe-shared-feature-auth';
import { HelpIconButtonData } from '@noah-labs/fe-shared-feature-help';
import { ForgottenPasswordSuccessScene } from '@noah-labs/fe-shared-ui-auth';
import { AppContainer, Switch404 } from '@noah-labs/fe-shared-ui-components';
import { TpAuthStatus } from '@noah-labs/fe-shared-ui-shared';
import { authRoutes } from '@noah-labs/fe-shared-util-routes';
import type { AwsCaptchaApi, SardineFlows } from '@noah-labs/shared-schema-gql';
import { Route } from 'react-router-dom';
import type { PpAccessControlData } from './AccessControlData';

export type PpRouter = {
  AccessControlData: (props: PpAccessControlData) => React.ReactElement;
  authedPath: string;
  captcha: AwsCaptchaApi | undefined;
  helpButton?: React.ReactNode;
  referralCode: string | null;
  updateGuestSardineConfig: (flow: SardineFlows) => string;
};

export function Router({
  AccessControlData,
  authedPath,
  captcha,
  helpButton,
  referralCode,
  updateGuestSardineConfig,
}: PpRouter): React.ReactElement {
  return (
    <Switch404>
      <Route exact path={authRoutes.signUp}>
        <AccessControlData needsAuthStatus={[TpAuthStatus.guest]} redirectAuthStatus={authedPath}>
          <AppContainer headTitle="Sign up">
            <SignUp
              captcha={captcha}
              helpButton={helpButton}
              referralCode={referralCode}
              updateGuestSardineConfig={updateGuestSardineConfig}
            />
          </AppContainer>
        </AccessControlData>
      </Route>
      <Route exact path={authRoutes.signIn}>
        <AccessControlData needsAuthStatus={[TpAuthStatus.guest]} redirectAuthStatus={authedPath}>
          <AppContainer headTitle="Login">
            <SignIn captcha={captcha} helpButton={helpButton} />
          </AppContainer>
        </AccessControlData>
      </Route>
      <Route exact path={authRoutes.signOut}>
        {/* TODO (cs): this is current behaviour, maybe this should only be available to pbGroup though, need to test */}
        <SignOut />
      </Route>
      <Route exact path={authRoutes.signedOut}>
        <AccessControlData needsAuthStatus={[TpAuthStatus.guest]}>
          <AppContainer>
            <SignedOut />
          </AppContainer>
        </AccessControlData>
      </Route>
      <Route exact path={authRoutes.verify}>
        <AccessControlData
          needsAuthStatus={[TpAuthStatus.authenticatedNotVerified]}
          redirectVerify={false}
        >
          <AppContainer headTitle="Verify your email">
            <VerifyEmail helpButton={helpButton} />
          </AppContainer>
        </AccessControlData>
      </Route>
      <Route exact path={authRoutes.recovery}>
        <AccessControlData needsAuthStatus={[TpAuthStatus.guest]}>
          <ForgottenPasswordWrapper>
            <ForgottenPassword helpButton={helpButton} returnTo={authRoutes.newPasswordEntry} />
          </ForgottenPasswordWrapper>
        </AccessControlData>
      </Route>
      <Route exact path={authRoutes.newPasswordEntry}>
        <AccessControlData needsAuthStatus={[TpAuthStatus.authenticated]}>
          <AppContainer headTitle="Choose a new password">
            <ForgottenPasswordNewPassword helpButton={<HelpIconButtonData />} />
          </AppContainer>
        </AccessControlData>
      </Route>
      <Route exact path={authRoutes.newPasswordComplete}>
        <AccessControlData needsAuthStatus={[TpAuthStatus.authenticated]}>
          <AppContainer headTitle="Success">
            <ForgottenPasswordSuccessScene helpButton={<HelpIconButtonData />} />
          </AppContainer>
        </AccessControlData>
      </Route>

      <Route exact path={authRoutes.error}>
        <AuthError />
      </Route>
    </Switch404>
  );
}
