/* eslint-disable react/jsx-no-useless-fragment */
import { Fragment } from 'react';
import type { ComponentType, ReactElement } from 'react';
import type { TpFeFeature, TpFeFeatureFlag } from '@noah-labs/fe-shared-data-access-user';
import { logger } from '@noah-labs/shared-logger/browser';
import type { PpWC } from '../types';
import { checkAuthStatus } from './checkAuthStatus';
import { checkFeature } from './checkFeature';
import type { TpAccessControlLoading, TpAccessControlRedirect } from './types';
import { TpAuthStatus } from './types';

export type PpAccessControl = PpWC & {
  Loading: ComponentType<TpAccessControlLoading>;
  Redirect: ComponentType<TpAccessControlRedirect>;
  location: {
    pathname: string;
    search: string;
  };
  needsAuthStatus?: Array<TpAuthStatus | Array<TpAuthStatus>> | undefined;
  needsFeature?: Array<TpFeFeature | Array<TpFeFeature>> | undefined;
  redirectAuthStatus: string;
  redirectFeature: string;
  redirectInvalid?: string | false;
  redirectVerify: string | false;
  userAuthStatus: TpAuthStatus;
  userFeatures: Map<TpFeFeature, TpFeFeatureFlag> | undefined;
  userIsFetched: boolean;
};

const disableAccessControls = process.env.GATSBY_DISABLE_ACCESS_CONTROL;

export function AccessControl({
  children,
  Loading,
  location,
  needsAuthStatus,
  needsFeature,
  Redirect,
  redirectAuthStatus,
  redirectFeature,
  redirectInvalid,
  redirectVerify,
  userAuthStatus,
  userFeatures,
  userIsFetched,
}: PpAccessControl): ReactElement {
  const { pathname, search } = location;
  const fullPath = search ? pathname.concat(search) : pathname;
  logger.debug('requested path:', fullPath);
  logger.debug('Auth Access Control', 'needs:', needsAuthStatus, 'has:', userAuthStatus);
  logger.debug('Feature Access Control', 'needs:', needsFeature, 'has:', userFeatures);

  /**
   * Redirect immediately if redirectInvalid path is provided
   */
  if (redirectInvalid) {
    const redirect = {
      pathname: redirectInvalid,
      state: { from: fullPath },
    };
    logger.debug('invalid route:', fullPath, 'redirecting to:', redirect);
    return <Redirect to={redirect} />;
  }

  /**
   * If userAuthStatus is unknown we don't know whether to redirect or not, so just 'wait'
   */
  if (checkAuthStatus({ has: userAuthStatus, needs: [TpAuthStatus.unknown] })) {
    logger.debug('access controls loading auth');
    return <Loading message="Please wait while you are authenticated..." />;
  }

  /**
   * If Access Controls are disabled, just render the route
   */
  if (disableAccessControls) {
    logger.debug('access controls disabled, rendering:', fullPath);
    return <Fragment>{children}</Fragment>;
  }

  /**
   * Redirect to verify screen if not verified & redirectVerify is requested
   */
  if (
    checkAuthStatus({ has: userAuthStatus, needs: [TpAuthStatus.authenticatedNotVerified] }) &&
    redirectVerify
  ) {
    const redirect = {
      pathname: redirectVerify,
      state: { from: fullPath },
    };
    logger.debug('user not verified, redirecting:', redirect);
    return <Redirect to={redirect} />;
  }

  /**
   * Check user's AuthStatus
   */
  const authStatusAllowed = checkAuthStatus({ has: userAuthStatus, needs: needsAuthStatus });
  logger.debug('authStatusAllowed:', authStatusAllowed);

  if (!authStatusAllowed) {
    logger.debug('access disabled due to auth control:', fullPath);

    const params = new URLSearchParams(search);
    params.set('from', pathname);

    const redirect = {
      pathname: redirectAuthStatus,
      search: params.toString(),
    };
    logger.debug('redirecting to:', redirect);
    return <Redirect to={redirect} />;
  }

  /**
   * AuthStatus is good, check user's feature access
   * If user is authenticated but not yet fetched, we don't know whether to redirect or not, so just 'wait'
   */
  if (
    checkAuthStatus({ has: userAuthStatus, needs: [TpAuthStatus.authenticated] }) &&
    !userIsFetched
  ) {
    logger.debug('access controls loading user features');
    return <Loading message="Please wait while we verify your access..." />;
  }
  const featureAllowed = checkFeature({ has: userFeatures, needs: needsFeature });
  logger.debug('featureAllowed:', featureAllowed);

  if (!featureAllowed) {
    logger.debug('access disabled due to feature control:', fullPath);

    const redirect = {
      pathname: redirectFeature,
      state: { from: fullPath },
    };
    logger.debug('redirecting to:', redirect);
    return <Redirect to={redirect} />;
  }
  logger.debug('rendering:', fullPath);
  return <Fragment>{children}</Fragment>;
}
