import { CloseCircleIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/CloseCircleIcon';
import {
  AppContainer,
  AppFooter,
  AppMain,
  LearnMoreButton,
  PrimaryButton,
  SceneIcon,
  SceneMain,
  SceneParagraph,
  SceneTitleLarge,
} from '@noah-labs/fe-shared-ui-components';
import { walletRoutes } from '@noah-labs/fe-shared-util-routes';
import { webLinks } from '@noah-labs/shared-constants';
import { Helmet } from 'react-helmet';

export type PpCompleteFailedScene = {
  allowTryAgain?: boolean;
  isResetting?: boolean;
  onTryAgain?: () => void;
  reason: React.ReactNode;
};

export function CompleteFailedScene({
  allowTryAgain,
  isResetting,
  onTryAgain,
  reason,
}: PpCompleteFailedScene): React.ReactElement {
  const AppFooterSlot = allowTryAgain ? (
    <PrimaryButton
      color="primaryBrand"
      data-qa="try-again-button"
      loading={isResetting}
      onClick={(): void => {
        if (!onTryAgain) {
          return;
        }

        onTryAgain();
      }}
    >
      Try again
    </PrimaryButton>
  ) : (
    <PrimaryButton data-qa="kyc-close-button" href={walletRoutes().base}>
      Close
    </PrimaryButton>
  );
  return (
    <AppContainer>
      <Helmet>
        <title>Identity verification has failed</title>
      </Helmet>
      <AppMain>
        <SceneMain dataQa="completeFailed">
          <SceneIcon>
            <CloseCircleIcon color="error" />
          </SceneIcon>
          <SceneTitleLarge>Identity verification has failed</SceneTitleLarge>
          <SceneParagraph>{reason}</SceneParagraph>
          <SceneParagraph>
            Verification helps us check that you’re really you and keeps your account safe from
            fraud.{' '}
            <LearnMoreButton href={webLinks.privacyPolicy.path} target="_blank">
              Learn more in our Privacy Policy.
            </LearnMoreButton>
          </SceneParagraph>
        </SceneMain>
      </AppMain>
      <AppFooter solidFade>{AppFooterSlot}</AppFooter>
    </AppContainer>
  );
}
