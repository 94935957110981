import { useEffect } from 'react';
import { useSigning } from '@noah-labs/fe-shared-feature-signing';
import { useUserInitUi } from '@noah-labs/fe-shared-feature-user';
import { useDialogs } from '@noah-labs/fe-shared-ui-components';
import { useUserLocalStorage } from '@noah-labs/fe-shared-ui-shared';
import { CreatePinReminderDialog } from '@noah-labs/fe-shared-ui-signing';
import { userRoutes, walletRoutes } from '@noah-labs/fe-shared-util-routes';
import dayjs from 'dayjs';
import { useRouteMatch } from 'react-router-dom';

const REMINDER_DAYS = 1;
const DIALOG_KEY = 'pin-create-reminder';

export function useCreatePinReminder(): void {
  const { data: userData } = useUserInitUi();
  const {
    isLoaded: isStorageLoaded,
    pinReminderDismissedTimestamp,
    setPinReminderDismissedTimestamp,
  } = useUserLocalStorage(userData?.userProfile.UserID);
  const { closeDialog, pushDialog } = useDialogs();
  const { sdStatus } = useSigning();
  const isWalletPath = useRouteMatch(walletRoutes().base);
  const shouldCheckLastSeen =
    isWalletPath?.isExact && sdStatus?.pinSetupRequired && isStorageLoaded;

  useEffect(() => {
    if (!shouldCheckLastSeen) {
      return;
    }

    const lpr = parseInt(pinReminderDismissedTimestamp, 10);
    if (!Number.isNaN(lpr) && dayjs().diff(dayjs.unix(lpr), 'day') < REMINDER_DAYS) {
      return;
    }

    pushDialog({
      Content: (
        <CreatePinReminderDialog
          setupTo={userRoutes.pin.create.pin}
          onSkip={(): void => {
            setPinReminderDismissedTimestamp(dayjs().unix().toString());
            closeDialog(DIALOG_KEY);
          }}
        />
      ),
      key: DIALOG_KEY,
      low: true,
      onDismiss: () => {
        setPinReminderDismissedTimestamp(dayjs().unix().toString());
      },
    });
  }, [
    closeDialog,
    pinReminderDismissedTimestamp,
    pushDialog,
    setPinReminderDismissedTimestamp,
    shouldCheckLastSeen,
  ]);
}
