import { Fragment } from 'react';
import { useIdentify, usePageView } from '@noah-labs/fe-shared-feature-analytics';
import { useAuth } from '@noah-labs/fe-shared-feature-auth';
import { useLoginKycReminder } from '@noah-labs/fe-shared-feature-kyc';
import { useSardine } from '@noah-labs/fe-shared-feature-sardine';
import { useUserInitUi } from '@noah-labs/fe-shared-feature-user';
import { Dialogs, LoadingPage, Switch404 } from '@noah-labs/fe-shared-ui-components';
import { useScrollToTop } from '@noah-labs/fe-shared-ui-shared';
import {
  authRoutes,
  kycRoutes,
  postSignupRoutes,
  userRoutes,
  walletRoutes,
} from '@noah-labs/fe-shared-util-routes';
import { Helmet } from 'react-helmet';
import { Route } from 'react-router-dom';
import { useCreatePinReminder } from '../hooks/useCreatePinReminder';
import { useInitialRedirect } from '../hooks/useInitialRedirect';
import { AuthRouterData } from '../modules/auth/AuthRouterData';
import { DisabledFeatureData } from '../modules/auth/scenes/feature-disabled/DisabledFeatureData';
import { Router as IntegrationRouter } from '../modules/integrations/Router';
import { routes as integrationRoutes } from '../modules/integrations/routes';
import { KycRouter } from '../modules/kyc/Router';
import { Router as MarketingRouter } from '../modules/marketing/Router';
import { routes as marketingRoutes } from '../modules/marketing/routes';
import { Router as RewardsRouter } from '../modules/rewards/Router';
import { routes as rewardsRoutes } from '../modules/rewards/routes';
import { PostSignupRouter } from '../modules/user/PostSignupRouter';
import { Router as UserRouter } from '../modules/user/Router';
import { Router as WalletRouter } from '../modules/wallet/Router';
import { webConfigBrowser } from '../webConfigBrowser';

const defaultAuthedPath = walletRoutes().base;

function App(): React.ReactElement {
  const { data: userData } = useUserInitUi();
  const { ready } = useInitialRedirect();
  const { updateGuestSardineConfig } = useSardine();
  useScrollToTop();

  /**
   * Analytics
   */
  const auth = useAuth();
  useIdentify({ email: auth.data?.email, userId: auth.data?.userId });
  usePageView();

  useCreatePinReminder();
  useLoginKycReminder();

  const captchaApi = webConfigBrowser.aws.captchaApi ?? undefined;

  if (!ready) {
    return <LoadingPage />;
  }

  return (
    <Fragment>
      <Helmet defaultTitle="Welcome" titleTemplate="%s | NOAH" />
      <Switch404>
        {/*
         * Auth routes need to be available to all users
         */}
        <Route path={authRoutes.base}>
          <AuthRouterData
            authedPath={defaultAuthedPath}
            captcha={webConfigBrowser.aws.captchaEnabled ? captchaApi : undefined}
            updateGuestSardineConfig={updateGuestSardineConfig}
          />
        </Route>
        {/*
         * PostSignup Routes
         * - username, consent and marketing updates screens
         * - in its own router because it needs a little state machine
         */}
        <Route exact path={postSignupRoutes}>
          <PostSignupRouter
            logoutTo={authRoutes.signOut}
            successTo={defaultAuthedPath}
            usernameDisplay={userData?.userProfile.UsernameDisplay}
          />
        </Route>
        {/*
         * Regular Routes
         */}
        <Route path={marketingRoutes.base}>
          <MarketingRouter />
        </Route>
        <Route path={userRoutes.base}>
          <UserRouter />
        </Route>
        <Route path={rewardsRoutes.base}>
          <RewardsRouter />
        </Route>
        <Route path={integrationRoutes.base}>
          <IntegrationRouter />
        </Route>
        <Route path={kycRoutes.base}>
          <KycRouter />
        </Route>
        <Route path={walletRoutes().base}>
          <WalletRouter />
        </Route>
        <Route path="/feature-disabled">
          <DisabledFeatureData />
        </Route>
        {/* Need to add a loading screen here to prevent a flash of 404 whilst deciding which route to send to */}
        <Route exact path="/">
          <LoadingPage />
        </Route>
      </Switch404>
      <Dialogs />
    </Fragment>
  );
}

export default App;
